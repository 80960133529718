import { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import ChargerType from "../../types/ChargerType"
import Comm from "../../Comm"
import styled from "styled-components"
import arrowIcon from '../../assets/icons/arrowIcon.png'
import { useTranslation } from "react-i18next"
import GeneralInformation from "./GeneralInformation"
import Charges from "./Charges"
import Statistics from "./Statistics"
import UsersRfid from "./UsersRfid"
import Logs from "./Logs"
import Settings from "./Settings"
import PushNotifications from "./PushNotifications"
import ExtLogUpload from "./ExtLogUpload"

const MenuContainer = styled.div`
  position: absolute;
  top: -1.5vh;
  display: flex;
`

const MenuBackIcon = styled.img`
  width: 16px;
  height: 16px;
  transform: rotate(-90deg);
  cursor: pointer;
  margin-top: 3px;
`

const MenuElement = styled.p<{ $selected: boolean }>`
  font-weight: 600;
  font-size: 12px;
  color: ${props => props.$selected ? props.theme.colors.gray_900 : props.theme.colors.gray_500};
  margin: 0px;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 2px;
  border-bottom: ${props => props.$selected ? `2px solid ${props.theme.colors.primary}` : '0px solid black'};
  cursor: pointer;
`

const TempChargerIdLabel = styled.p`
  position: absolute;
  top: -7vh;
  left: 1vw;
  font-size: 14px;
  font-weight: 700;
`

export default function Charger() {
  const { t } = useTranslation()
  const { chargerId, subpath } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const menuElements: string[] = ['generalInformation', 'charges', 'statistics', 'usersRfid', 'logs', 'settings', 'pushNotifications', 'extLogUpload']

  const [charger, setCharger] = useState<ChargerType>()
  const [page, setPage] = useState<string>(menuElements[0])

  async function fetchCharger() {
    // TODO: Update to the endpoint for one charger when possible
    const response = await Comm('/api-charger/chargers', {})
    const result = await response.json()

    for (const charger of result) {
      if (charger.chargerId == chargerId) {
        setCharger(charger)
      }
    }
  }
  useEffect(() => {
    fetchCharger()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (subpath) {
      setPage(subpath)
    } else {
      setPage('generalInformation')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  function navigateToSubpage(menuElement: string) {
    setPage(menuElement)
    if (menuElement == 'generalInformation') {
      navigate(`/chargers/${chargerId}`)
    } else {
      navigate(`/chargers/${chargerId}/${menuElement}`)
    }
  }


  return (
    <>
      <MenuContainer>
        <MenuBackIcon src={arrowIcon} onClick={() => navigate('/chargers')} />
        <table>
          <tbody>
            <tr>
              {menuElements.map(menuElement => (
                <td key={menuElement}>
                  <MenuElement $selected={page == menuElement} onClick={() => navigateToSubpage(menuElement)}>{t(`charger.${menuElement}`)}</MenuElement>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </MenuContainer>
      <TempChargerIdLabel>{chargerId}</TempChargerIdLabel>
      {charger && (
        <>
          {page == 'generalInformation' && <GeneralInformation charger={charger} />}
          {page == 'charges' && <Charges charger={charger} />}
          {page == 'statistics' && <Statistics />}
          {page == 'usersRfid' && <UsersRfid charger={charger} />}
          {page == 'logs' && <Logs />}
          {page == 'settings' && <Settings charger={charger} />}
          {page == 'pushNotifications' && <PushNotifications charger={charger} />}
          {page == 'extLogUpload' && <ExtLogUpload charger={charger} />}
        </>
      )}
    </>
  )
}