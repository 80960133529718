import { useTranslation } from "react-i18next"
import WhiteSpace from "../../components/Whitespace"
import PageTitle from "../../components/PageTitle"
import ChargerType from "../../types/ChargerType"
import styled from "styled-components"
import { useEffect, useState } from "react"
import ChargeType from "../../types/ChargeType"
import Comm from "../../Comm"
import LoaderDots from "../../components/LoaderDots"
import normalChargingIconBlack from "../../assets/icons/normalChargingIconBlack.png"
import energyIcon from "../../assets/icons/energyIcon.png"
import timeIcon from "../../assets/icons/timeIcon.png"
import averageIcon from "../../assets/icons/averageIcon.png"
import Navigator from "../../components/Navigator"
import backIcon from "../../assets/icons/backIcon.svg"

const GeneralContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: 18px;
  border-radius: 24px;
  position: relative;
  width: 62%;
`

const StatusContainer = styled.div`
  width: 12.5vw;
  height: 12.5vw;
  background-color: lightgreen;
  border-radius: 16px;
`

const ChargerNameLabel = styled.p`
  font-weight: 700;
  font-size: 18px;
  color: ${props => props.theme.colors.gray_900};
  position: absolute;
  left: 15vw;
  top: 34px;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`

const ChargerNameValue = styled.p`
  font-weight: 700;
  font-size: 28px;
  color: ${props => props.theme.colors.gray_900};
  position: absolute;
  padding-right: 30px;
  left: 15vw;
  top: 56px;
`

const UserIdEmailLabel = styled.p`
  font-weight: 700;
  font-size: 12px;
  color: ${props => props.theme.colors.gray_500};
  position: absolute;
  left: 32vw;
  top: 38px;
`

const UserIdEmailValue = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: ${props => props.theme.colors.gray_900};
  position: absolute;
  left: 32vw;
  top: 56px;
`

const GeneralDetailsTabel = styled.table`
  position: absolute;
  left: calc(12.5vw + 18px + 18px);
  top: 150px;
  width: calc(100% - 12.5vw - 18px - 18px);
`

const GeneralDetailLabel = styled.p`
  font-weight: 700;
  font-size: 12px;
  color: ${props => props.theme.colors.gray_500};
  margin: 0px;
`

const GeneralDetailValue = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
`

const ConnectionContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: 18px;
  border-radius: 24px;
  position: relative;
`

const ConnectionTitle = styled.p`
  font-weight: 600;
  font-size: 18px;
  color: ${props => props.theme.colors.gray_900};
`

const ConnectionTableCell = styled.td`
  display: flex;
  width: 33.3%;
  margin-top: 12px;
`

const ConnectionLabel = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_500};
  margin: 0px;
  width: 10vw;
`

const ConnectionValue = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
  margin-top: -1px;
`

const ConnectionTableDivider = styled.p<{ $left: string }>`
  position: absolute;
  height: 134px;
  width: 0px;
  border: 1px solid ${props => props.theme.colors.gray_200};
  left: ${props => props.$left};
  top: 60px;
`

const StatCardTable = styled.table`
  width: 35%;
  position: absolute;
  right: 0px;
`

const StatCard = styled.div<{ $color: string }>`
  border-radius: 24px;
  padding: 24px;
  background-color: ${props => props.theme.colors[props.$color]};
  display: flex;
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
  height: calc(((12.5vw - 24px - 36px) / 2) - 10px);
`

const StatCardIcon = styled.img`
  background-color: ${props => props.theme.colors.white};
  width: 18px;
  height: 18px;
  padding: 7px;
  border-radius: 100px;
  position: absolute;
  right: 24px;
  top: 24px;
`

const StatCardTitle = styled.p<{ $white?: boolean }>`
  font-weight: 700;
  font-size: 12px;
  color: ${props => props.$white ? props.theme.colors.white : props.theme.colors.dark};
  margin: 0px;
`

const StatCardValue = styled.p<{ $white?: boolean }>`
  font-weight: 700;
  font-size: 24px;
  color: ${props => props.$white ? props.theme.colors.white : props.theme.colors.dark};
  margin: 0px;
  margin-top: calc(((12.5vw - 24px - 36px) / 2) - 10px - 36px);
`

const StatCardUnit = styled.span`
  font-weight: 500;
  font-size: 15px;
  padding-left: 6px;
`

export default function GeneralInformation({ charger }: { charger: ChargerType }) {
  const { t } = useTranslation()

  const [charges, setCharges] = useState<ChargeType[]>()

  const [allChargedEnergy, setAllChargedEnergy] = useState<number>()
  const [allChargetime, setAllChargetime] = useState<number>()
  const [chargerDetails, setChargerDetails] = useState<{ipAddress: string, macAddress: string, connection: string, dlmState: string, midMeterState: string}>()

  async function fetchCharges() {
    const response = await Comm('/api-cdr/cdrs/last', {
      headers: { "Charger_ID": charger.chargerId }
    })
    const result = await response.json()
    result.reverse()
    setCharges(result)


    // Calculate stats
    let chargedEnergy = 0
    let chargedTime = 0
    for (const charge of result) {
      chargedEnergy += charge.chg_energy
      chargedTime += charge.chg_time
    }

    setAllChargedEnergy(chargedEnergy)
    setAllChargetime(chargedTime)
  }

  async function fetchChargerDetails() {
    const CMD_GET_PRODUCT_NFO_response = await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({"msg_type": 12})
    })
    const CMD_GET_PRODUCT_NFO_result = await CMD_GET_PRODUCT_NFO_response.json()

    const CMD_GET_CONNECTION_STAT_response = await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({"msg_type": 22})
    })
    const CMD_GET_CONNECTION_STAT_result = await CMD_GET_CONNECTION_STAT_response.json()

    let connectionMode = ''
    switch(CMD_GET_CONNECTION_STAT_result.active_nwi) {
      case 1:
      case 2:
        connectionMode = 'ethernet'
        break;
      case 3:
        connectionMode = 'wifi'
        break;
      case 4:
      case 5:
      case 6:
        connectionMode = 'gsm'
        break;
      default:
        connectionMode = 'unknown'
        break;
    }

    setChargerDetails({
      ipAddress: connectionMode == 'ethernet' ? CMD_GET_CONNECTION_STAT_result.ethernet.ip_address : connectionMode == 'wifi' ? CMD_GET_CONNECTION_STAT_result.wifi.ip : t(`charger.unknown`),
      macAddress: connectionMode == 'ethernet' ? CMD_GET_CONNECTION_STAT_result.ethernet.mac_address : connectionMode == 'wifi' ? CMD_GET_CONNECTION_STAT_result.wifi.mac : t(`charger.unknown`),
      connection: t(`charger.${connectionMode}`),
      dlmState: CMD_GET_PRODUCT_NFO_result.dlm_state.toString(),
      midMeterState: CMD_GET_PRODUCT_NFO_result.mid_state.toString()
    })
  }

  useEffect(() => {
    fetchCharges()
    if (charger.online) {
      fetchChargerDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function secondsToHumanReadable(seconds: number) {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds - (hours * 3600)) / 60)

    if (hours.toString() == '0') {
      return `${hours}${t('charger.m')}`
    } else {
      return `
        ${hours}${t('charger.h')} 
        ${minutes}${t('charger.m')} 
      `
    }
  }

  function chargerDlmAndMidStateCodeToMeaning(code: string) {
    switch(parseInt(code)) {
      case 0: return 'UNAVAILABLE_ERROR'
      case 1: return 'UNAVAILABLE'
      case 2: return 'CONNECTED_NOT_IN_USE'
      case 3: return 'CONNECTED_IN_USE'
    }
  }

  return (
    <>
      <WhiteSpace height={'12px'} />
      <PageTitle label={t('charger.generalInformation')} />
      <WhiteSpace height={'12px'} />
      <div style={{display: 'flex'}}>

        <GeneralContainer>
          <StatusContainer>

          </StatusContainer>
            <ChargerNameLabel>{t('charger.chargerName')}</ChargerNameLabel>
          <ChargerNameValue>{charger.localName}</ChargerNameValue>
          <UserIdEmailLabel>{t('charger.userIdEmail')}</UserIdEmailLabel>
          <UserIdEmailValue>
            <Navigator newTab to={`https://console.firebase.google.com/project/voltie-charger/database/voltie-charger-default-rtdb/data/~2Fvoltie~2Fchargerusers~2F${charger.userId}`}>
                {charger.userId}<img src={backIcon} style={{ transform: 'rotate(180deg)', width: '16px', marginLeft: '5px' }} />
            </Navigator>
            <br />{charger.userEmail}
          </UserIdEmailValue>
          <GeneralDetailsTabel>
            <tbody>
              <tr>
                <td>
                  <GeneralDetailLabel>{t('charger.group')}</GeneralDetailLabel>
                  <GeneralDetailValue>NeedToFetch</GeneralDetailValue>
                </td>
                <td>
                  <GeneralDetailLabel>{t('charger.chargerId')}</GeneralDetailLabel>
                  <GeneralDetailValue>{charger.chargerId}</GeneralDetailValue>
                </td>
                <td>
                  <GeneralDetailLabel>{t('charger.registrationDate')}</GeneralDetailLabel>
                  <GeneralDetailValue>{charger.registrationDate}</GeneralDetailValue>
                </td>
                <td>
                  <GeneralDetailLabel>{t('charger.serialNumber')}</GeneralDetailLabel>
                  <GeneralDetailValue>{charger.serialNumber == ' ' ? '-' : charger.serialNumber || '-'}</GeneralDetailValue>
                </td>
              </tr>
            </tbody>
          </GeneralDetailsTabel>
        </GeneralContainer>
        <StatCardTable>
          <tbody>
            <tr>
              <td style={{ width: '50%' }}>
                <StatCard $color="dark">
                  <StatCardIcon src={normalChargingIconBlack} />
                  <div>
                    <StatCardTitle $white>{t('charger.allCharges')}</StatCardTitle>
                    <StatCardValue $white>{charges ? charges.length : <LoaderDots />}<StatCardUnit>{t('charger.pcs')}</StatCardUnit></StatCardValue>
                  </div>
                </StatCard>
              </td>
              <td style={{ width: '50%' }}>
                <StatCard $color="warning">
                  <StatCardIcon src={energyIcon} />
                  <div>
                    <StatCardTitle>{t('charger.allChargedEnergy')}</StatCardTitle>
                    <StatCardValue>{allChargedEnergy ? allChargedEnergy.toFixed(2) : <LoaderDots />}<StatCardUnit>kWh</StatCardUnit></StatCardValue>
                  </div>
                </StatCard>
              </td>
            </tr>
            <tr>
              <td>
                <WhiteSpace height="8px" />
              </td>
            </tr>
            <tr>
              <td style={{ width: '50%' }}>
                <StatCard $color="primary">
                  <StatCardIcon src={timeIcon} />
                  <div>
                    <StatCardTitle>{t('charger.allChargetime')}</StatCardTitle>
                    <StatCardValue>{allChargetime ? secondsToHumanReadable(allChargetime) : <LoaderDots />}</StatCardValue>
                  </div>
                </StatCard>
              </td>
              <td style={{ width: '50%' }}>
                <StatCard $color="dark">
                  <StatCardIcon src={averageIcon} />
                  <div>
                    <StatCardTitle $white>{t('charger.realySwitches')}</StatCardTitle>
                    <StatCardValue $white>?<StatCardUnit>{t('charger.pcs')}</StatCardUnit></StatCardValue>
                  </div>
                </StatCard>
              </td>
            </tr>
          </tbody>
        </StatCardTable>
      </div>
      <WhiteSpace height="18px" />
      <ConnectionContainer>
        <ConnectionTitle>{t('charger.connection')}</ConnectionTitle>
        <ConnectionTableDivider $left="31%" />
        <ConnectionTableDivider $left="64%" />
        <table style={{ width: '100%' }}>
          <tbody>
            <tr style={{ display: 'flex' }}>
              <ConnectionTableCell>
                <ConnectionLabel>{t('charger.ipAddress')}</ConnectionLabel>
                <ConnectionValue>{charger.online ? chargerDetails?.ipAddress || <LoaderDots /> : '-'}</ConnectionValue>
              </ConnectionTableCell>
              <ConnectionTableCell>
                <ConnectionLabel>{t('charger.dlmState')}</ConnectionLabel>
                <ConnectionValue>{charger.online ? chargerDetails ? chargerDlmAndMidStateCodeToMeaning(chargerDetails.dlmState) : <LoaderDots /> : '-'}</ConnectionValue>
              </ConnectionTableCell>
              <ConnectionTableCell>
                <ConnectionLabel>{t('charger.software')}</ConnectionLabel>
                <ConnectionValue>{charger.swVersion}</ConnectionValue>
              </ConnectionTableCell>
            </tr>
            <tr style={{ display: 'flex' }}>
              <ConnectionTableCell>
                <ConnectionLabel>{t('charger.macAddress')}</ConnectionLabel>
                <ConnectionValue>{charger.online ? chargerDetails?.macAddress || <LoaderDots /> : '-'}</ConnectionValue>
              </ConnectionTableCell>
              <ConnectionTableCell>
                <ConnectionLabel>{t('charger.midMeterState')}</ConnectionLabel>
                <ConnectionValue>{charger.online ? chargerDetails ? chargerDlmAndMidStateCodeToMeaning(chargerDetails.midMeterState) : <LoaderDots /> : '-'}</ConnectionValue>
              </ConnectionTableCell>
              <ConnectionTableCell>
                <ConnectionLabel>{t('charger.firmware')}</ConnectionLabel>
                <ConnectionValue>{charger.fwVersion}</ConnectionValue>
              </ConnectionTableCell>
            </tr>
            <tr style={{ display: 'flex' }}>
              <ConnectionTableCell>
                <ConnectionLabel>{t('charger.connection')}</ConnectionLabel>
                <ConnectionValue>{charger.online ? chargerDetails?.connection || <LoaderDots /> : '-'}</ConnectionValue>
              </ConnectionTableCell>
              <ConnectionTableCell></ConnectionTableCell>
              <ConnectionTableCell>
                <ConnectionLabel>{t('charger.operatingSystem')}</ConnectionLabel>
                <ConnectionValue>{charger.osVersion}</ConnectionValue>
              </ConnectionTableCell>
            </tr>
            <tr style={{ display: 'flex' }}>
              <ConnectionTableCell>
                <ConnectionLabel>{t('charger.serverConnection')}</ConnectionLabel>
                <ConnectionValue>{charger.lastPresence}</ConnectionValue>
              </ConnectionTableCell>
              <ConnectionTableCell></ConnectionTableCell>
              <ConnectionTableCell></ConnectionTableCell>
            </tr>
          </tbody>
        </table>
      </ConnectionContainer>
    </>
  )
}