import { useState } from "react"
import ButtonWithIcon from "../components/ButtonWithIcon"
import Modal from "../components/Modal"
import styled from "styled-components"
import WhiteSpace from "../components/Whitespace"
import exportIconWhite from "../assets/icons/exportIconWhite.png"
import exportIcon from "../assets/icons/exportIcon.png"
import xIcon from "../assets/icons/xIcon.svg"
import checkboxFullIcon from "../assets/icons/checkboxFullIcon.png"
import checkboxEmptyIcon from "../assets/icons/checkboxEmptyIcon.png"
import { useOutletContext } from "react-router-dom"
import { useTranslation } from "react-i18next"

const SmallModalContainer = styled.div`
  padding: 24px;
`

const ModalCloseButton = styled.img<{ $theme: string }>`
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 26px;
  right: 26px;
  filter: invert(${props => props.$theme == 'dark' ? 1 : 0});
`

const ExportModalLabel = styled.div`
  font-weight: 600;
  font-size: 15px;
  color: ${props => props.theme.colors.gray_900};
`

const ExportModalSubLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_500};
`

const ExportModalFormatCheckbox = styled.img`
  width: 16px;
  height: 16px;
  margin-top: 14px;
  margin-right: 8px;
`

const ExportModalFormatLabel = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
`

export default function ExportModal({ onExport, title }: { onExport: (formats: string[]) => void, title: string }) {
  const { t } = useTranslation()
  const [theme] = useOutletContext<string>()
  
  const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false)
  const [exportSelectedFormats, setExportSelectedFormats] = useState<string[]>([])

  function handleToggleExportFormats(format: string) {
    const newExportSelectedFormats = [...exportSelectedFormats]
    if (newExportSelectedFormats.includes(format)) {
      newExportSelectedFormats.splice(newExportSelectedFormats.indexOf(format), 1)
    } else {
      newExportSelectedFormats.push(format)
    }
    setExportSelectedFormats(newExportSelectedFormats)
  }

  function handleExport() {
    onExport(exportSelectedFormats)
    setIsExportModalOpen(false)
  }

  return (
    <>
      <ButtonWithIcon icon={exportIcon} label={t('chargerLogs.export')} onClick={() => setIsExportModalOpen(true)} backgroundColor="white" />
      {isExportModalOpen && (
        <Modal>
          <SmallModalContainer>
            <ModalCloseButton $theme={theme} src={xIcon} onClick={() => setIsExportModalOpen(false)} />
            <ExportModalLabel>{title}</ExportModalLabel>
            <ExportModalSubLabel>*{t('chargerLogs.youCanChooseMultipleFileTypes')}</ExportModalSubLabel>
            <WhiteSpace height="8px" />
            {['.TXT', '.CSV', '.JSON', '.XLS'].map(format => (
              <div key={format} style={{ display: 'flex', cursor: 'pointer' }} onClick={() => handleToggleExportFormats(format)}>
                <ExportModalFormatCheckbox src={exportSelectedFormats.includes(format) ? checkboxFullIcon : checkboxEmptyIcon} />
                <ExportModalFormatLabel><b>{format}</b> {t('chargerLogs.fileExport')}</ExportModalFormatLabel>
              </div>
            ))}
            <WhiteSpace height="16px" />
            <div style={{ width: "40%" }}>
              <ButtonWithIcon icon={exportIconWhite} label={t('chargerLogs.export')} onClick={handleExport} />
            </div>
          </SmallModalContainer>
        </Modal>
      )}
    </>
  )
}