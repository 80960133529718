import InputWithLabel from "./InputWithLabel"
import eyeIcon from '../assets/icons/eyeIcon.svg'
import eyeDisabledIcon from '../assets/icons/eyeDisabledIcon.svg'
import styled from "styled-components"
import { useState } from "react"

const Icon = styled.img`
  position: absolute;
  width: 24px;
  height: 24px;
  margin-left: calc(100% - 8vw - 40px);
  margin-top: 12px;
  cursor: pointer;

  &:active {
    transform: scale(.9);
  }
`

export default function PasswordInputWithLabel({ label, width, value, onInput, isError }: { label: string, width: string, value: string, onInput: (value: string) => void, isError?: boolean }) {
  const [isHidePassword, setIsHidePassword] = useState<boolean>(true)
  
  return (
    <>
      <Icon src={isHidePassword ? eyeIcon : eyeDisabledIcon} onClick={() => setIsHidePassword(!isHidePassword)} />
      <InputWithLabel value={value} onInput={onInput} label={label} width={width} isPassword={isHidePassword} isError={isError} />
    </>
  )
}
