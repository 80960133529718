import styled from "styled-components"

const Container = styled.div<{ $width: string }>`
  width: ${props => props.$width};
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.gray_300};
  padding: 12px;
  display: flex;
  position: relative;
`

const Icon = styled.img<{ $theme: string }>`
  width: 24px;
  height: 24px;
`

const Label = styled.p`
  font-weight: 500;
  font-size: 12px;
  color: ${props => props.theme.colors.gray_500};
  line-height: 0px;
  margin: 0px;
  margin-top: 4px;
  margin-left: 12px;
`

const Value = styled.p`
  font-weight: 700;
  font-size: 12px;
  color: ${props => props.theme.colors.gray_900};
  line-height: 0px;
  margin: 0px;
  margin-top: 16px;
  margin-left: 12px;
`

export default function ListItem({ theme, width, icon, label, value }: { theme: string, width: string, icon: string, label: string, value: string }) {
  return (
    <Container $width={width}>
      <Icon $theme={theme} src={icon} />
      <div>
        <Label>{label}</Label>
        <Value>{value}</Value>
      </div>
    </Container>
  )
}