import styled from "styled-components"

const Container = styled.div<{ $backgroundColor?: string }>`
  background-color: ${props => props.$backgroundColor ? props.theme.colors[props.$backgroundColor] : props.theme.colors.primary};
  border-radius: 6px;
  padding: 6px;
  display: flex;
  cursor: pointer;
  outline: ${props => props.$backgroundColor == 'white' ? `1px solid ${props.theme.colors.gray_300}` : 'none'};

  &:active {
    transform: scale(.98);
  }
`

const Icon = styled.img<{ $recolorIconTo?: 'white' | 'black' }>`
  width: 16px;
  height: 16px;
  margin-left: 6px;
  filter: ${props => props.$recolorIconTo == 'white' ? 'brightness(100)' : props.$recolorIconTo == 'black' ? 'brightness(0)' : 'none'};
`

const Label = styled.p<{ $backgroundColor?: string }>`
  font-weight: 600;
  font-size: 14px;
  color: ${props => props.$backgroundColor == 'white' ? props.theme.colors.dark : props.theme.colors.white};
  margin-bottom: 0px;
  margin-top: -2px;
  margin-left: 6px;
  margin-right: 6px;
`

export default function ButtonWithIcon({ icon, label, onClick, backgroundColor, recolorIconTo }: { icon?: string, label: string, onClick: () => void, backgroundColor?: string, recolorIconTo?: 'white' | 'black' }) {
  return (
    <Container onClick={onClick} $backgroundColor={backgroundColor}>
      {icon && (
        <Icon src={icon} $recolorIconTo={recolorIconTo} />
      )}
      <Label $backgroundColor={backgroundColor}>{label}</Label>
    </Container>
  )
}