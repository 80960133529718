import { useTranslation } from "react-i18next"
import styled from "styled-components"
import Dropdown from "../components/Dropdown"
import { useEffect } from "react"
import backIcon from "../assets/icons/backIcon.svg"
import forwardIcon from "../assets/icons/forwardIcon.svg"
import downIcon from "../assets/icons/downIcon.svg"

const Container = styled.div<{ $width: string, $notAbsolute?: boolean }>`
  width: ${props => props.$width};
  position: ${props => props.$notAbsolute ? 'relative' : 'absolute'};
  bottom: 8px;
  background-color: ${props => props.theme.background};
  display: flex;
`

const NumberOfEntriesLabel = styled.p`
  font-weight: 500;
  font-size: 12px;
  color: ${props => props.theme.colors.gray_600};
`

const DropDownContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: 6px;
  margin-left: 6px;
  margin-right: 6px;
  font-weight: 900;
`

const StepperContainer = styled.div`
  position: absolute;
  right: 0px;
  display: flex;
`

const Arrow = styled.img`
  background-color: ${props => props.theme.colors.white};
  border-radius: 6px;
  padding: 7px;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 5px;
  width: calc(30px - 14px);
  height: calc(30px - 14px);
  cursor: pointer;
`

const Page = styled.p<{ $selected: boolean }>`
  font-weight: 500;
  font-size: 12px;
  color: ${props => props.$selected ? props.theme.colors.white : props.theme.colors.gray_600};
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 5px;
  padding: 7px;
  width: calc(30px - 14px);
  height: calc(30px - 14px);
  border-radius: 6px;
  text-align: center;
  background-color: ${props => props.$selected ? props.theme.colors.primary : 'transparent'};
  cursor: pointer;
`

export default function PaginationNavigator({ theme, width, numberOfEntries, page, setPage, step, setStep, notAbsolute }: { theme: string, width: string, numberOfEntries: number, page: number, setPage: (value: number) => void, step: number, setStep: (value: number) => void, notAbsolute?: boolean }) {
  const { t } = useTranslation()

  useEffect(() => {
    const preferredStep = localStorage.getItem('preferredPaginationStep')
    if (preferredStep) {
      setStep(parseInt(preferredStep))
    } else {
      setStep(100)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleChooseStep(value: string) {
    localStorage.setItem('preferredPaginationStep', value)
    setStep(parseInt(value))
    setPage(1)
  }

  return (
    <Container $width={width} $notAbsolute={notAbsolute}>
      <NumberOfEntriesLabel>{t('paginationNavigator.showing')}</NumberOfEntriesLabel>
      <DropDownContainer>
        <Dropdown theme={theme} handleChoose={(value) => handleChooseStep(value)} title={{ label: step.toString(), img: downIcon }} options={[{ label: '50' }, { label: '100' }, { label: '250' }, { label: '500' }, { label: '1000' }, { label: '10000' }]} showOptionContainerOnTop />
      </DropDownContainer>
      <NumberOfEntriesLabel>{`${t('paginationNavigator.of')} ${numberOfEntries} ${t('paginationNavigator.entries')}`}</NumberOfEntriesLabel>
      <StepperContainer>
        <Arrow src={backIcon} onClick={() => page > 1 && setPage(page - 1)} />
        {numberOfEntries > 0 && <>
          {page > 2 && (
            <Page $selected={false} onClick={() => setPage(1)}>{'1'}</Page>
          )}
          {page > 3 && (
            <Page $selected={false}>{'...'}</Page>
          )}
          {numberOfEntries && step && Array(Math.ceil(numberOfEntries / step)).fill(0).map((_n, index) => (
            Math.abs((index + 1) - page) < 2 &&
            <Page key={index + 1} $selected={index + 1 == page} onClick={() => setPage(index + 1)}>{index + 1}</Page>
          ))}
          {page + 2 < Math.ceil(numberOfEntries / step) && (
            <Page $selected={false}>{'...'}</Page>
          )}
          {page + 1 < Math.ceil(numberOfEntries / step) && (
            <Page $selected={false} onClick={() => setPage(Math.ceil(numberOfEntries / step))}>{Math.ceil(numberOfEntries / step)}</Page>
          )}
        </>}
        <Arrow src={forwardIcon} onClick={() => page < Math.ceil(numberOfEntries / step) && setPage(page + 1)} />
      </StepperContainer>
    </Container>
  )
}