import styled from "styled-components";
import arrowIcon from "../assets/icons/arrowIcon.png"
import Navigator from "../components/Navigator";
import { TableCellTypes } from "../enums/TableCellTypes";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import InputWithIcon from "../components/InputWithIcon";
import searchIcon from "../assets/icons/searchIcon.png"
import WhiteSpace from "../components/Whitespace";
import PaginationNavigator from "./PaginationNavigator";

interface ColumnType {
  label?: string,
  width?: string
}

interface CellType {
  link?: string,
  sortBy?: string | number,
  onClick?: () => unknown,
  value?:
  { type: TableCellTypes.Image, src: string } |
  { type: TableCellTypes.Label, value: string } |
  { type: TableCellTypes.LabelDivided, value1: string, value2: string } |
  { type: TableCellTypes.Status, label: string, themeColor?: string, hexColor?: string, labelBelow?: string | null } |
  { type: TableCellTypes.Icon, src: string } |
  { type: TableCellTypes.OpenButton } |
  { type: TableCellTypes.LabelWithIcon, value: string, src: string } |
  { type: TableCellTypes.Text, value: string },
  custom?: ReactNode
}




const Container = styled.div<{ $height: string }>`
  width: 100%;
  height: ${props => props.$height};
  background-color: ${props => props.theme.colors.white};
  border-radius: 16px;
  overflow-y: scroll;
`

const TableElement = styled.table`
  width: 100%;
  border-collapse: collapse;
`

const InfoRow = styled.tr`
  border-bottom: 1px solid ${props => props.theme.colors.gray_300};
`

const InfoLabel = styled.p`
  font-weight: 600;
  font-size: 10px;
  color: ${props => props.theme.colors.gray_500};
`

const Row = styled.tr`
  border-bottom: 1px dashed ${props => props.theme.colors.gray_300};
  position: relative;
`

const SortIcon = styled.img<{ $isSelected: boolean, $isDescending: boolean }>`
  width: 12px;
  height: 12px;
  margin-top: 10px;
  margin-left: 6px;
  filter: ${props => props.$isSelected ? 'invert(44%) sepia(95%) saturate(1126%) hue-rotate(182deg) brightness(101%) contrast(95%)' : 'brightness(1)'};
  transform: ${props => props.$isDescending && props.$isSelected ? 'rotate(0deg)' : 'rotate(180deg)'};
`


// --- Cell Presets ---

// Image
const Image = styled.img`
  width: 30px;
  height: 30px;
  margin-left: 9px;
  margin-right: 16px;
`

// Label
const Label = styled.p`
  font-weight: 700;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
  line-height: 0px;
  margin-top: 16px;
`

// LabelDivided
const LabelDividedTop = styled.p`
  font-weight: 700;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
  line-height: 0px;
  margin-top: 16px;
`
const LabelDividedBottom = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_500};
  line-height: 0px;
  margin-top: 16px;
`

// Status
const Status = styled.p<{ $themeColor?: string, $hexColor?: string, $leaveSpaceForLableBelow?: boolean }>`
  font-weight: 600;
  font-size: 12px;
  color: ${props => props.theme.colors.white};
  border-radius: 40px;
  width: 70px;
  line-height: 22px;
  text-align: center;
  margin-top: ${props => props.$leaveSpaceForLableBelow ? '5px' : ''};
  background-color: ${props => props.$themeColor ? props.theme.colors[props.$themeColor] : props.$hexColor};
`
const LabelBelowStatus = styled.p`
  font-weight: 400;
  font-size: 11px;
  color: ${props => props.theme.colors.gray_500};
  line-height: 0px;
  margin-top: -4px;
  width: 100px;
  margin-left: -15px;
  text-align: center;
`

// Icon
const Icon = styled.img`
  width: 12px;
  height: 12px;
  margin-left: 9px;
  margin-right: 16px;
`

// OpenButton
const OpenButton = styled.img`
  width: 16px;
  height: 16px;
  padding: 7px;
  background-color: #EFF2F5;
  border-radius: 6px;
  margin-right: 24px;
  transform: rotate(90deg);
  float: right;

  &:active {
    transform: rotate(90deg) scale(.9);
  }
`

// LabelWithIcon
const LabelWithIcon_Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 4px;
  margin-top: 6px;
`

// Text
const Text = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
  line-height: 0px;
  margin-top: 16px;
`

export default function Table({ id, columns, data, height, isSearchEnabled, isPaginationEnabled }: { id: string, columns: ColumnType[], data: CellType[][], height: string, isSearchEnabled: boolean, isPaginationEnabled: boolean }) {
  
  const { t } = useTranslation()
  const [theme] = useOutletContext<string>()

  const [showing, setShowing] = useState<CellType[][]>(data)
  const [sortedColumn, setSortedColumn] = useState<number>()
  const [isSortDescending, setIsSortDescending] = useState<boolean>()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [page, setPage] = useState<number>(1)
  const [pageStep, setPageStep] = useState<number>(0)

  // Sort on load according to the preferedTableSort, use column 1 if there is no preference
  useEffect(() => {
    const sortPreferenceString = localStorage.getItem('preferedTableSort')
    if (sortPreferenceString) {
      const sortPreference = JSON.parse(sortPreferenceString)
      if (sortPreference[id]) {
        handleSort(sortPreference[id].column, sortPreference[id].isDescending)
      } else {
        handleSort(1)  
      }
    } else {
      handleSort(1)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleSearch(term: string) {
    const result = []
    for (const row of data) {
      for (const cell of row) {
        if (JSON.stringify({...cell, custom: ''}).toLocaleLowerCase().includes(term.toLocaleLowerCase())) {
          result.push(row)
          break
        }
      }
    }
    setShowing(result)
  }

  useEffect(() => {
    if (searchTerm != '') {
      handleSearch(searchTerm)
    } else {
      setShowing(data)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm])

  function handleSort(column: number, isDescending?: boolean) {
    // Only allow sorting on columns that have sortBy key
    if (data[0][column].sortBy != undefined) {
      if (sortedColumn == column || isDescending) {
        if (isSortDescending) {
          data.sort((a, b) => (a[column].sortBy || '') < (b[column].sortBy || '') ? -1 : 1)
          setIsSortDescending(false)
          updateSortPreference(column, false)
        } else {
          data.sort((a, b) => (a[column].sortBy || '') > (b[column].sortBy || '') ? -1 : 1)
          setIsSortDescending(true)
          updateSortPreference(column, true)
        }
      } else {
        data.sort((a, b) => (a[column].sortBy || '') < (b[column].sortBy || '') ? -1 : 1)
        setIsSortDescending(false)
        updateSortPreference(column, false)
      }
      handleSearch(searchTerm)
      setSortedColumn(column)
    }
  }

  function updateSortPreference(column: number, isDescending: boolean) {
    const currentPreferenceString = localStorage.getItem('preferedTableSort')
    let currentPreference = {}
    if (currentPreferenceString) {
      currentPreference = JSON.parse(currentPreferenceString) as {[key: string | number]: unknown}
    } else {
      currentPreference = {} as {[key: string | number]: unknown}
    }

    //@ts-expect-error For some reason it thinks that an object cant be indexed with a string, not sure what the problem might be
    currentPreference[id] = { column, isDescending }
    localStorage.setItem('preferedTableSort', JSON.stringify(currentPreference))
  }

  return (
    <>
      {isSearchEnabled && <>
        <InputWithIcon onInput={(input) => setSearchTerm(input)} theme={theme} width="12vw" placeholder={t('chargers.search')} icon={searchIcon} />
      </>}
      <WhiteSpace height="12px" />
      <Container $height={height}>
        <TableElement>
          <tbody>
            <InfoRow>
              {columns.map((column, columnIndex) => (
                <td key={`column${columnIndex}`} style={column.width ? { width: column.width } : {}}>
                  {column.label && (
                    <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => data[0][columnIndex].sortBy != undefined && handleSort(columnIndex)}>
                      <InfoLabel>{column.label}</InfoLabel>
                      {data[0][columnIndex].sortBy != undefined && (
                        <SortIcon $isSelected={sortedColumn == columnIndex} $isDescending={isSortDescending || false} src={arrowIcon} />
                      )}
                    </div>
                  )}
                </td>
              ))}
            </InfoRow>
            {showing.map((row, rowIndex) => (isPaginationEnabled ? rowIndex + 1 > (page - 1) * pageStep && rowIndex < page * pageStep : true) && (
              <Row key={`row${rowIndex}`}>
                {row.map((cell, cellIndex) => (
                  <td key={`row${rowIndex}cell${cellIndex}`} onClick={cell.onClick} style={cell.onClick ? { cursor: 'pointer' } : {}}>
                    <Navigator to={cell.link || ''}>
                      {cell.value && (
                        <>
                          {cell.value.type == TableCellTypes.Image && <>
                            <Image src={cell.value.src} />
                          </>}

                          {cell.value.type == TableCellTypes.Label && <>
                            <Label>{cell.value.value}</Label>
                          </>}

                          {cell.value.type == TableCellTypes.LabelDivided && <>
                            <LabelDividedTop>{cell.value.value1}</LabelDividedTop>
                            <LabelDividedBottom>{cell.value.value2}</LabelDividedBottom>
                          </>}

                          {cell.value.type == TableCellTypes.Status && <>
                            {cell.value.labelBelow ? <>
                              <Status $themeColor={cell.value.themeColor} $hexColor={cell.value.hexColor} $leaveSpaceForLableBelow>{cell.value.label}</Status>
                              <LabelBelowStatus>{cell.value.labelBelow}</LabelBelowStatus>
                            </> : <>
                              <Status $themeColor={cell.value.themeColor} $hexColor={cell.value.hexColor}>{cell.value.label}</Status>
                            </>}
                          </>}

                          {cell.value.type == TableCellTypes.Icon && <>
                            <Icon src={cell.value.src} />
                          </>}

                          {cell.value.type == TableCellTypes.OpenButton && <>
                            <OpenButton src={arrowIcon} />
                          </>}

                          {cell.value.type == TableCellTypes.LabelWithIcon && <>
                          <div style={{ display: 'flex' }}>
                            <LabelWithIcon_Icon src={cell.value.src} />
                            <Label>{cell.value.value}</Label>
                          </div>
                          </>}

                          {cell.value.type == TableCellTypes.Text && <>
                            <Text>{cell.value.value}</Text>
                          </>}
                        </>
                      )}
                      {!cell.value && cell.custom && cell.custom}
                    </Navigator>
                  </td>
                ))}
              </Row>
            ))}
          </tbody>
        </TableElement>
      </Container>
      {showing && isPaginationEnabled && <>
        <PaginationNavigator theme={theme} width="100%" numberOfEntries={showing.length} page={page} setPage={setPage} step={pageStep} setStep={setPageStep} />
      </>}
    </>
  )
}