import { useState, useEffect } from "react";

export default function LoaderDots() {

  const [loaderCharacters, setLoaderCharacters] = useState<string>('.')


  useEffect(() => {
    const interval = setTimeout(() => {
      if (loaderCharacters.length == 1) {
        setLoaderCharacters('..')
      } else if (loaderCharacters.length == 2) {
        setLoaderCharacters('...')
      } else if (loaderCharacters.length == 3) {
        setLoaderCharacters('.')
      }

    }, 200);

    return () => clearTimeout(interval)
  }, [loaderCharacters]);

  return (
    <span>{loaderCharacters}</span>
  )
}