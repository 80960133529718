import { useEffect, useState } from "react"
import { useDetectClickOutside } from "react-detect-click-outside"
import styled from "styled-components"
import Navigator from "./Navigator"

interface dropdownOption {
  img?: string,
  label: string,
  color?: string,
  link?: string
}

const Container = styled.div`
  
`

const OptionsContainer = styled.table<{ $showOptionContainerOnLeft?: boolean, $showOptionContainerOnTop?: boolean, $isDark?: boolean }>`
  position: absolute;
  background-color: ${props => props.$isDark ? props.theme.colors.dark : props.theme.colors.white};
  border-radius: 8px;
  box-shadow: 4px 8px 32px 0px #00000033;
  z-index: 100;
  margin-left: ${props => props.$showOptionContainerOnLeft ? '-110px' : '0px'};
  transform: translateY(${props => props.$showOptionContainerOnTop ? '-115%' : '0%'});
`

const OptionImg = styled.img<{ $theme?: string }>`
  width: 16px;
  height: 16px;
  margin-right: 4px;
  margin-top: 3px;
  filter: invert(${props => props.$theme == 'dark' ? 1 : 0});
`

const OptionLabel = styled.span<{ $color?: string }>`
  font-size: 12px;
  margin-left: 5px;
  color: ${props => props.$color ? props.$color : props.theme.colors.gray_700};
`

const OptionContainer = styled.div<{ $color?: string }>`
  display: flex;
  cursor: pointer;
  padding: 12px;

  &:hover {
    ${OptionLabel} {
      color: ${props => props.$color ? props.$color : props.theme.colors.gray_900};
    }
  }
`

export default function Dropdown({ title, options, handleChoose, theme, showOptionContainerOnLeft, showOptionContainerOnTop, isDark }: { title?: dropdownOption, options: dropdownOption[], handleChoose: (value: string) => void, theme?: string, showOptionContainerOnLeft?: boolean, showOptionContainerOnTop?: boolean, isDark?: boolean }) {

  const [selected, setSelected] = useState<dropdownOption>(title ? title : options[0])
  const [isOpen, setIsOpen] = useState<boolean>(false)

  function handleSelect(option: dropdownOption) {
    if (!title) {
      setSelected(option)
    }
    setIsOpen(false)
    handleChoose(option.label)
  }


  useEffect(() => {
    if (title) {
      setSelected(title)
    }
  }, [title])

  return (
    <Container ref={useDetectClickOutside({ onTriggered: () => setIsOpen(false) })}>
      <OptionContainer onClick={() => setIsOpen(!isOpen)} $color={selected.color}>
        {selected.img && (
          <OptionImg $theme={theme} src={selected.img} />
        )}
        <OptionLabel $color={selected.color}>{selected.label}</OptionLabel>
      </OptionContainer>
      {isOpen && (
        <OptionsContainer $showOptionContainerOnLeft={showOptionContainerOnLeft} $showOptionContainerOnTop={showOptionContainerOnTop} $isDark={isDark}>
          <tbody>
            {options.map(option => (
              <tr key={option.img + option.label}>
                <td>
                  <OptionContainer $color={option.color} onClick={() => handleSelect(option)}>
                    {option.img && (
                      <OptionImg $theme={theme} src={option.img} />
                    )}
                    {option.label != '' && (
                      <>
                      {option.link ? (
                        <Navigator to={option.link}>
                          <OptionLabel $color={option.color}>{option.label}</OptionLabel>
                        </Navigator>
                      ) : (
                        <a>
                          {/* Need to put it in an a tag because it is still a rendering element for some reason */}
                          <OptionLabel $color={option.color}>{option.label}</OptionLabel>
                        </a>
                      )}
                      </>
                    )}
                  </OptionContainer>
                </td>
              </tr>
            ))}
          </tbody>
        </OptionsContainer>
      )}
    </Container>
  )
}