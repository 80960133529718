import { useState } from "react";
import Comm from "../../Comm";
import PageTitle from "../../components/PageTitle";
import WhiteSpace from "../../components/Whitespace";
import ChargerType from "../../types/ChargerType";
import { useTranslation } from "react-i18next";
import React from "react";
import LoaderDots from "../../components/LoaderDots";
import InputWithLabel from "../../components/InputWithLabel";
import Button from "../../components/Button";
import checkboxEmptyIcon from "../../assets/icons/checkboxEmptyIcon.png"
import checkboxFullIcon from '../../assets/icons/checkboxFullIcon.png'

export default function ExtLogUpload({ charger }: { charger: ChargerType }) {
  const { t } = useTranslation()

  const [extPath, setExtPath] = useState<string>('log')
  const [extFilter, setExtFilter] = useState<string>('ocpp')
  const [extFilesOnPath, setExtFilesOnPath] = useState<string[]>([])
  const [selectedExtFilesOnPath, setSelectedExtFilesOnPath] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  async function getFilesFromChargerAtPath() {
    const response = await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 86, "path": extPath, "filter": extFilter })
    })
    setExtFilesOnPath((await response.json()).fnames.sort())
  }

  function selectFile(file: string) {
    if (!selectedExtFilesOnPath.includes(file)) {
      setSelectedExtFilesOnPath([...selectedExtFilesOnPath, file])
    }
  }

  function unSelectFile(file: string) {
    if (selectedExtFilesOnPath.includes(file)) {
      const allFilesWithoutFile = []
      for (const selectedFile of selectedExtFilesOnPath) {
        if (selectedFile != file) {
          allFilesWithoutFile.push(selectedFile)
        }
      }
      setSelectedExtFilesOnPath(allFilesWithoutFile)
    }
  }

  async function downloadFiles() {
    if (selectedExtFilesOnPath.length > 0) {
      const packFilesResponse = await Comm('/api-websocket/mobile', {
        method: "POST",
        headers: {
          "Charger_id": charger.chargerId,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ "msg_type": 88, "path": extPath, "fnames": selectedExtFilesOnPath })
      })
      const zipName = (await packFilesResponse.json()).outname

      await Comm('/api-websocket/mobile', {
        method: "POST",
        headers: {
          "Charger_id": charger.chargerId,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ "msg_type": 90, "fname": zipName, "del": true })
      })

      setIsLoading(true)
      downloadFileFromServer(zipName.split('/')[1])
    }
  }

  async function downloadFileFromServer(zip: string) {
    const uploadedFilesResponse = await Comm(`/api-log/charger/extra/loglist?charger_id=${charger.chargerId}`, {})
    const uploadedFilesResult = await uploadedFilesResponse.json()

    let foundTheFile = false

    for (const log of uploadedFilesResult) {
      if (log.logTitle == zip) {

        foundTheFile = true

        const uploadedFilesResponse = await Comm(`/api-log/charger/extra/log?object_id=${log.id}`, {})
        const file = await uploadedFilesResponse.blob()

        const downloadAnchorNode = document.createElement('a')
        downloadAnchorNode.setAttribute("href", window.URL.createObjectURL(file))
        downloadAnchorNode.setAttribute("download", log.logTitle)
        document.body.appendChild(downloadAnchorNode) // required for firefox
        downloadAnchorNode.click()
        downloadAnchorNode.remove()
        setIsLoading(false)


      }
    }

    if (!foundTheFile) {
      setTimeout(() => downloadFileFromServer(zip), 1000)
    }
  }

  return (
    <>
      <WhiteSpace height={'18px'} />
      <PageTitle label={t('charger.extLogUpload')} />
      <WhiteSpace height={'12px'} />

      <div style={{ display: 'flex' }}>
        <InputWithLabel label={'Path'} width='15%' value={extPath} onInput={(e) => extFilesOnPath.length == 0 && setExtPath(e)} />
        <WhiteSpace width="8px" />
        <InputWithLabel label={'Filter'} width='15%' value={extFilter} onInput={(e) => extFilesOnPath.length == 0 && setExtFilter(e)} />
      </div>
      {extFilesOnPath.length == 0 && (
          <>
            <WhiteSpace height="8px" />
            <Button label="Get files" width='calc(30% + 8px)' onclick={getFilesFromChargerAtPath} />
          </>
        )}
      <WhiteSpace height="8px" />
      {extFilesOnPath.length != 0 && (
        <div style={{ maxHeight: '30vh', width: 'calc(30% + 8px)', overflowY: 'scroll', border: '1px solid #E4E6EF', borderRadius: '8px' }}>
          {extFilesOnPath && extFilesOnPath.map(file => (
            <React.Fragment key={file}>
              {selectedExtFilesOnPath.includes(file) ? (
                <div style={{ cursor: 'pointer', display: 'flex' }}>
                  <WhiteSpace width="8px" />
                  <img src={checkboxFullIcon} style={{ height: '16px', marginTop: '14px' }} />
                  <WhiteSpace width="8px" />
                  <p style={{ fontWeight: '600', lineHeight: '10px' }} onClick={() => unSelectFile(file)}>{file}</p>
                </div>
              ) : (
                <div style={{ cursor: 'pointer', display: 'flex' }}>
                  <WhiteSpace width="8px" />
                  <img src={checkboxEmptyIcon} style={{ height: '16px', marginTop: '14px' }} />
                  <WhiteSpace width="12px" />
                  <p style={{ lineHeight: '10px' }} onClick={() => selectFile(file)}>{file}</p>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      )}
      <WhiteSpace height="8px" />
      {selectedExtFilesOnPath.length > 0 && (
        <>
          {!isLoading ? (
            <Button label='Download selected files' width='calc(30% + 8px)' onclick={downloadFiles} />
          ) : (
            <LoaderDots />
          )}
        </>
      )}
    </>
  )
}