import { useEffect, useState } from "react"
import styled from "styled-components"
import copyIcon from "../assets/icons/copyIcon.svg"
import jsonIcon from "../assets/icons/jsonIcon.svg"
import Modal from "./Modal"
import xIcon from "../assets/icons/xIcon.svg"

const Container = styled.div<{ $show: boolean }>`
  position: absolute;
  top: ${props => props.$show ? '-90px' : '-150px'};
  right: 70px;
  padding: 8px;
  border-radius: 8px;
  transition: top .7s;
  background-color: ${props => props.theme.colors.white};
  border: 2px solid ${props => props.theme.colors.gray_600};
  z-index: 500;
`

const Label = styled.p`
  font-weight: 700;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
`

const CopyButton = styled.img`
  width: 16px;
  height: 16px;
  padding: 8px;
  background-color: ${props => props.theme.colors.white};
  border: 2px solid ${props => props.theme.colors.gray_600};
  border-radius: 8px;
  position: absolute;
  right: -40px;
  top: -2px;
  cursor: pointer;

  &:active {
    transform: scale(.9);
  }
`

const ModalCopyButton = styled.img`
  width: 16px;
  height: 16px;
  padding: 8px;
  background-color: ${props => props.theme.colors.white};
  border-radius: 12px;
  position: absolute;
  right: -40px;
  top: 0px;
  cursor: pointer;

  &:active {
    transform: scale(.9);
  }
`

const OpenJsonButton = styled.img`
  width: 16px;
  height: 16px;
  padding: 8px;
  background-color: ${props => props.theme.colors.white};
  border: 2px solid ${props => props.theme.colors.gray_600};
  border-radius: 8px;
  position: absolute;
  right: -78px;
  top: -2px;
  cursor: pointer;

  &:active {
    transform: scale(.9);
  }
`

const ModalCloseButton = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
`

const JsonText = styled.pre`
  padding: 32px;
  max-height: 80vh;
  overflow-y: scroll;

  .string { color: green; }
  .number { color: darkorange; }
  .boolean { color: blue; }
  .null { color: magenta; }
  .key { color: darkred; }
`

export default function Notification({ label, setState }: { label: string, setState: (value: string) => void }) {

  const [isShowing, setIsShowing] = useState<boolean>(true)
  const [isHovering, setIsHovering] = useState<boolean>(false)
  const [isShowingJson, setIsShowingJson] = useState<boolean>(false)

  useEffect(() => {
    if (!isHovering && !isShowingJson) {
      const timeout = setTimeout(() => {
        // Setting the original state to falsy to stop rendering the notification box
        setState('')
        setIsShowing(false)
      }, 15000)

      return () => clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHovering, isShowingJson])

  function jsonSyntaxHighlight(json: string) {
    if (!json) return ""; //no JSON from response

    json = json
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
    return json.replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
      function (match) {
        let cls = "number";
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = "key";
          } else {
            cls = "string";
          }
        } else if (/true|false/.test(match)) {
          cls = "boolean";
        } else if (/null/.test(match)) {
          cls = "null";
        }
        return '<span class="' + cls + '">' + match + "</span>";
      }
    );
  }

  return (
    <>
      <Container $show={isShowing} onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
        <Label onDoubleClick={() => { setState(''); setIsShowing(false) }}>{label.length > 100 ? `${label.slice(0, 100)}...` : label}</Label>
        <CopyButton src={copyIcon} onClick={() => navigator.clipboard.writeText(label)} />
        <OpenJsonButton src={jsonIcon} onClick={() => setIsShowingJson(true)} />
      </Container>
      {isShowingJson && (
        <Modal>
          <ModalCloseButton src={xIcon} onClick={() => setIsShowingJson(false)} />
          <ModalCopyButton src={copyIcon} onClick={() => navigator.clipboard.writeText(label)} />
          <JsonText dangerouslySetInnerHTML={{ __html: jsonSyntaxHighlight(JSON.stringify(JSON.parse(label), undefined, 2)) }} />
        </Modal>
      )}
    </>
  )
}