import styled from "styled-components"
import removeItemIcon from "../assets/icons/removeItemIcon.svg"

const Container = styled.div<{ $width: string }>`
  width: ${props => props.$width};
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.gray_300};
  padding: 12px;
  display: flex;
  position: relative;
`

const Icon = styled.img<{ $theme: string }>`
  width: 24px;
  height: 24px;
`

const Label = styled.p`
  font-weight: 500;
  font-size: 12px;
  color: ${props => props.theme.colors.gray_500};
  line-height: 0px;
  margin: 0px;
  margin-top: 4px;
  margin-left: 12px;
`

const Value = styled.p`
  font-weight: 700;
  font-size: 12px;
  color: ${props => props.theme.colors.gray_900};
  line-height: 0px;
  margin: 0px;
  margin-top: 16px;
  margin-left: 12px;
`

const DeleteButton = styled.img<{ $theme: string }>`
  width: 16px;
  height: 16px;
  position: absolute;
  right: 12px;
  top: 16px;
  cursor: pointer;
  filter: invert(${props => props.$theme == 'dark' ? 1 : 0});
`

export default function ListItemWithDeleteButton({ theme, width, icon, label, value, handleDelete }: { theme: string, width: string, icon: string, label: string, value: string, handleDelete: () => void }) {
  return (
    <>
      <Container $width={width}>
        <Icon $theme={theme} src={icon} />
        <div>
          <Label>{label}</Label>
          <Value>{value}</Value>
        </div>
        <DeleteButton $theme={theme} src={removeItemIcon} onClick={handleDelete} />
      </Container>
    </>
  )
}