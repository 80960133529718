import styled from "styled-components"

const Container = styled.div`
  position: relative;
`

const InputElement = styled.input<{ $width: string }>`
  background: none;
  border: 1px solid ${props => props.theme.colors.gray_300};
  border-radius: 8px;
  width: calc(${props => props.$width} - 32px);
  outline: none;
  padding: 12px;
  padding-left: 40px;
  background-color: ${props => props.theme.colors.white};
`

const Icon = styled.img<{ $theme: string }>`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 12px;
  left: 12px;
  filter: invert(${props => props.$theme == 'dark' ? 1 : 0});
`

export default function InputWithIcon({ theme, width, placeholder, icon, onInput }: { theme: string, width: string, placeholder: string, icon: string, onInput: (value: string) => void }) {
  return (
    <Container>
      <InputElement onInput={(e) => onInput((e.target as HTMLInputElement).value)} $width={width} placeholder={placeholder} />
      <Icon $theme={theme} src={icon} />
    </Container>
  )
}