import ReactDOM from 'react-dom/client'
import App from './App.tsx'

import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import Login from './pages/Login.tsx'

import './i18n';
import AppVersion from './pages/AppVersion.tsx';
import ChargerGroups from './pages/ChargerGroups.tsx';
import Logs from './pages/Logs/Logs.tsx';
import ChargerLogs from './pages/Logs/ChargerLogs.tsx';
import Charger from './pages/Chargers/Charger.tsx';
import Dashboard from './pages/Dashboard.tsx';
import Chargers from './pages/Chargers/Chargers.tsx';

import * as Sentry from "@sentry/react";

// Init sentry
Sentry.init({
  dsn: "https://4f7d9621827c079b0abdbfef2cf0a298@o4507984421781504.ingest.de.sentry.io/4507984429842512",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["https://admin.voltie.eu/", "https://voltie-frontend-upgrade.web.app/"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <a>error</a>,
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "/chargers",
        element: <Chargers />,
      },
      {
        path: "/chargers/:chargerId/:subpath?",
        element: <Charger />,
      },
      {
        path: "/chargergroups",
        element: <ChargerGroups />,
      },
      {
        path: "/logs",
        element: <Logs />,
      },
      {
        path: "/logs/charger/:chargerId",
        element: <ChargerLogs />,
      },
      {
        path: "/appversion",
        element: <AppVersion />,
      },
      {
        path: "/users",
        element: <p>Users</p>,
      },
      {
        path: "/settings",
        element: <p>Settings</p>,
      },
      {
        path: "/notifications",
        element: <p>Notifications</p>,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/resetpassword",
    element: <Login />
  }
])

ReactDOM.createRoot(document.getElementById('root')!).render(
  <>
    <RouterProvider router={router} />
  </>
)
