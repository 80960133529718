import { useTranslation } from "react-i18next"
import WhiteSpace from "../../components/Whitespace"
import PageTitle from "../../components/PageTitle"
import styled from "styled-components"
import magnifierIcon from "../../assets/icons/magnifierIcon.png"
import displayIcon from "../../assets/icons/displayIcon.png"
import lightIcon from "../../assets/icons/lightIcon.png"
import speakerIcon from "../../assets/icons/speakerIcon.png"
import powerIcon from "../../assets/icons/powerIcon.png"
import connectionIcon from "../../assets/icons/connectionIcon.png"
import midIcon from "../../assets/icons/midIcon.png"
import rfidIcon from "../../assets/icons/rfidIcon.png"
import ocppIcon from "../../assets/icons/ocppIcon.png"
import forwardIcon from "../../assets/icons/forwardIcon.svg"
import logIcon from "../../assets/icons/logIcon.png"
import calibrateIcon from "../../assets/icons/calibrateIcon.png"
import resetIcon from "../../assets/icons/resetIcon.png"
import rebootIcon from "../../assets/icons/rebootIcon.png"
import wrenchIcon from "../../assets/icons/wrenchIcon.png"
import errorIcon from "../../assets/icons/errorIcon.png"
import settingsIcon from "../../assets/icons/settingsIcon.svg"
import Select from "../../components/Select"
import { useOutletContext } from "react-router-dom"
import ButtonWithIcon from "../../components/ButtonWithIcon"
import { useEffect, useState } from "react"
import Comm from "../../Comm"
import ChargerType from "../../types/ChargerType"
import Modal from "../../components/Modal"
import xIcon from "../../assets/icons/xIcon.svg"
import amperIcon from "../../assets/icons/amperIcon.png"
import homeIcon from "../../assets/icons/homeIcon.png"
import chargerSmallIcon from "../../assets/icons/chargerSmallIcon.png"
import powerYellowIcon from "../../assets/icons/powerYellowIcon.png"
import voltageIcon from "../../assets/icons/voltageIcon.png"
import thermometerIcon from "../../assets/icons/thermometerIcon.svg"
import humidityIcon from "../../assets/icons/humidityIcon.svg"
import Slider from "../../components/Slider"
import flagHun from "../../assets/flagHun.svg"
import flagUk from "../../assets/flagUk.svg"
import Switch from "../../components/Switch"
import dimIcon from "../../assets/icons/dimIcon.png"
import brightIcon from "../../assets/icons/brightIcon.png"
import Notification from "../../components/Notification"
import Button from "../../components/Button"
import refreshIcon from "../../assets/icons/refreshIcon.png"
import averageIcon from "../../assets/icons/averageIcon.png"
import warningIcon from "../../assets/icons/warningIcon.svg"
import solarGreenModeIcon from "../../assets/icons/solarGreenModeIcon.png"
import solarEcoModeIcon from "../../assets/icons/solarEcoModeIcon.png"
import wifiIcon from "../../assets/icons/wifiIcon.svg"
import ethernetIcon from "../../assets/icons/ethernetIcon.svg"
import cellularIcon from "../../assets/icons/cellularIcon.svg"

interface ChargerValuesType {
  power_stat: {
    current1: number,
    current2: number,
    current3: number,
    power1: number,
    power2: number,
    power3: number,
    voltage1: number,
    voltage2: number,
    voltage3: number,
    temp_uc: number,
    temp_pow: number,
    temp_pi: number,
    hum: number,
    dlm_valid: boolean,
    dlm_current1: number,
    dlm_current2: number,
    dlm_current3: number
  }
  config: {
    wifi: {
      mode: number,
      ssid: string,
      passwd: string,
      ap_mode_ssid: string
    },
    ethernet: {
      mode: number,
      ip_address: string,
      netmask: string,
      gateway: string,
      dns: string
    },
    gsm: {
      mode: number,
      apn: string,
      apn_uname: string,
      apn_pwd: string,
      sim_pin: string,
      pin_error: boolean,
      nw_reg_to_sec: number,
      IDs: {
        iccid: string,
        imei: string,
        imsi: string,
        revision: string
      }
    },
    display: {
      enabled: boolean,
      night_mode_en: boolean,
      night_period_sp: number,
      night_period_st: number,
      on_seconds: number,
      rotate180: boolean,
      language: number,
      eyes_en: boolean,
      tap_rfid_interval: number,
      brightness: number,
      scroll_speed: number
    },
    front_led: {
      enabled: boolean,
      brightness: number,
      night_mode_en: boolean,
      night_period_st: number,
      night_period_sp: number
    },
    rear_led: {
      enabled: boolean,
      brightness: number,
      night_mode_en: boolean,
      night_period_st: number,
      night_period_sp: number,
      color_r: number,
      color_g: number,
      color_b: number
    },
    sound: {
      enabled: boolean,
      night_mode_en: boolean,
      night_period_st: number,
      night_period_sp: number
    },
    localisation: {
      gmt_offset: number,
      zoneinfo: string
    },
    power: {
      phase_order: number,
      curr_usr_limit: number,
      curr_night_limit: number,
      night_mode_en: boolean,
      night_period_st: number,
      night_period_sp: number,
      mid_meter_en: boolean,
      mid_meter_type: number,
      dlm_mode: number,
      dlm_meter_type: number,
      dlm_dyn_curr_lim: number,
      dlm_dyn_specific: boolean,
      dlm_dyn_curr1_lim: number,
      dlm_dyn_curr2_lim: number,
      dlm_dyn_curr3_lim: number,
      dlm_dyn_ud_time: number,
      dlm_dyn_dd_time: number,
      dlm_ecogrn_id_time: number,
      dlm_grid_u_stop: number,
      dlm_grid_u_min: number,
      dlm_grid_u_max: number,
      dlm_grid_t_up: number,
      dlm_grid_t_dn: number,
      curr_hw_limit: number,
      mid_meter_state: number,
      dlm_meter_state: number,
      night_mode: boolean,
      curr_hw_limit_min: number
    },
    pi2pi_netw: {
      role: number,
      port: number,
      master1_ip: string,
      master2_ip: string,
      top_master1_ip: string,
      top_master2_ip: string,
      segment_id: number,
      ps_enabled: boolean,
      ps_autonom_curr: number
    }
  }
}

const Container = styled.div`
  width: 100%;
  height: calc(90vh - 70px);
  overflow-y: scroll;
`

const SettingContainer = styled.div<{ $color?: string, $noAnimation?: boolean, $noFlex?: boolean }>`
  background-color: ${props => props.$color ? props.theme.colors[props.$color] : 'transparent'};
  padding: 24px;
  margin: 8px;
  display: ${props => props.$noFlex ? 'block' : 'flex'};
  border-radius: 24px;
  position: relative;
  cursor: pointer;

  &:active {
    ${props => !props.$noAnimation && `
      transform: scale(.985);
    `}
  }
`

const SettingIcon = styled.img<{ $small?: boolean }>`
  width: ${props => props.$small ? '25px' : '40px'};
  height: ${props => props.$small ? '25px' : '40px'};
  padding: ${props => props.$small ? '7.5px' : '0px'};
`

const SettingLabel = styled.p<{ $white?: boolean, $small?: boolean }>`
  font-weight: 700;
  font-size: ${props => props.$small ? '14px' : '18px'};
  color: ${props => props.$white ? props.theme.colors.white : props.theme.colors.dark};
  margin: 0px;
  margin-top: ${props => props.$small ? '10px' : '8px'};
  margin-left: 12px;
`

const SettingArrow = styled.img<{ $white?: boolean }>`
  width: 20px;
  height: 20px;
  filter: brightness(${props => props.$white ? 100 : 0});
  position: absolute;
  right: 24px;
  margin-top: 10px;
`

const DividerLineColor = styled.div`
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-image: linear-gradient(to right, #2A6FF4, #E2594B);
`

const DividerLineGray = styled.div`
  width: 100%;
  height: 1px;
  border-radius: 1px;
  background-color: ${props => props.theme.colors.gray_300};
`

const CustomCommandsIconBackground = styled.div`
  background-color: ${props => props.theme.colors.dark};
  height: 40px;
  width: 40px;
  border-radius: 10px;
`

const CustomCommandsTextInput = styled.textarea`
  width: 100%;
  height: 100px;
  resize: none;
  outline: none;
  border: 1px solid ${props => props.theme.colors.gray_300};
  border-radius: 8px;
`

const ModalTitle = styled.p`
  font-weight: 600;
  font-size: 20px;
  margin: 0px;
  color: ${props => props.theme.colors.gray_900};
  width: 20vw;
`

const ModalSubcategoryTitle = styled.p` // When there is a back icon before the label
  font-weight: 600;
  font-size: 20px;
  margin: 0px;
  color: ${props => props.theme.colors.gray_900};
  width: calc(20vw - 22px - 8px);
`

const ModalContainer = styled.div`
  padding: 24px;
  height: 85vh;
`

const ModalCloseButton = styled.img<{ $theme: string }>`
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 26px;
  right: 26px;
  filter: invert(${props => props.$theme == 'dark' ? 1 : 0});
`

const ModalButtonContainer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: calc(100% - 10px);
  background-color: ${props => props.theme.colors.background};
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
`

const ModalButton = styled.p<{ $hasBg?: boolean }>`
  font-weight: 600;
  font-size: 15px;
  color: ${props => props.$hasBg ? props.theme.colors.white : props.theme.colors.gray_500};
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 16px;
  width: 20%;
  text-align: center;
  border-radius: 12px;
  float: right;
  background-color: ${props => props.$hasBg ? props.theme.colors.dark : 'none'};
  cursor: pointer;
`

const ModalDetailTitleIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 4px;
  margin-right: 4px;
`

const ModalDetailTitle = styled.p`
  font-weight: 700;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
  margin-top: 4px;
`

const ModalDetailTitleThin = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
  margin-top: 4px;
`

const ModalDetailDivider = styled.div<{ $isColor?: boolean }>`
  height: ${props => props.$isColor ? '2px' : '1px'};
  width: 100%;
  margin-top: 18px;
  margin-bottom: 18px;
  background-color: ${props => props.$isColor ? 'none' : props.theme.colors.gray_300};
  background-image: ${props => props.$isColor ? 'linear-gradient(to right, #2A6FF4, #E2594B)' : 'none'};
`

const DetailedStatsValue = styled.p`
  font-weight: 700;
  font-size: 24px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
`

const DetailedStatsValueUnit = styled.span`
  font-weight: 500;
  font-size: 15px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
  margin-left: 2px;
`

const TimeInput = styled.input<{ $right?: boolean }>`
  border: 1px solid ${props => props.theme.colors.gray_400};
  border-radius: 8px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  outline: none;
  position: ${props => props.$right ? 'absolute' : 'relative'};
  right: ${props => props.$right ? '20px' : 'auto'};
`

const ColorInput = styled.input`
  width: 32.5px;
  height: 32.5px;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &::-webkit-color-swatch {
    border-radius: 15px;
    border: 2px solid ${props => props.theme.colors.gray_400};
  }

  &::-moz-color-swatch {
    border-radius: 30px;
    border: 2px solid ${props => props.theme.colors.gray_400};
  }
`

const SmallModalContainer = styled.div`
  padding: 24px;
`

const SmallModalLabel = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
`

const SmallModalButtonContainer = styled.div`
  width: 15vw;
  display: flex;
`

const ConnectionsBackIcon = styled.img`
  width: 22px;
  height: 22px;
  transform: rotate(180deg);
  margin-right: 8px;
  cursor: pointer;
`

const ConnectionActiveLabel = styled.p`
  font-weight: 700;
  font-size: 14px;
  color: ${props => props.theme.colors.primary};
  position: absolute;
  right: 52px;
  margin-top: 10px;
`

export default function Settings({ charger }: { charger: ChargerType }) {
  const { t } = useTranslation()
  const [theme] = useOutletContext<string>()

  const [chargerValues, setChargerValues] = useState<ChargerValuesType>()
  const [notification, setNotification] = useState<string>('')
  const [isSendingBottomRowCommand, setIsSendingBottomRowCommand] = useState<string | null>()

  const [customCommandInputValue, setCustomCommandInputValue] = useState<string>('')
  const [customCommandDropdownOptions] = useState<{ [key: string]: { msg_type?: number, sub_cmd?: number } }>({
    '': {},
    'Force software upgrade check': { msg_type: 15, sub_cmd: 1 },
    'Force firmware upgrade check': { msg_type: 15, sub_cmd: 2 },
    'Force log upload': { msg_type: 15, sub_cmd: 3 },
    'Clear init': { msg_type: 15, sub_cmd: 4 },
    'Delete CDR records (except last)': { msg_type: 15, sub_cmd: 6 },
    'Calibrate EVSE': { msg_type: 15, sub_cmd: 8 },
    'Reset factory defaults': { msg_type: 15, sub_cmd: 9 },
    'Reboot PI': { msg_type: 15, sub_cmd: 10 },
    'Force firmware reflash': { msg_type: 15, sub_cmd: 11 },
    'Software restart (without reboot)': { msg_type: 15, sub_cmd: 12 },
    'Force firmware reset': { msg_type: 15, sub_cmd: 13 },
    'GSM Modem test': { msg_type: 15, sub_cmd: 15 },
    'Toggle PS display': { msg_type: 15, sub_cmd: 17 },
    'Drop Logs': { msg_type: 15, sub_cmd: 18 },
    'Spotlight Display': { msg_type: 15, sub_cmd: 19 },
    'Reset Log Limit': { msg_type: 15, sub_cmd: 20 },
    'Fan ON': { msg_type: 15, sub_cmd: 21 },
    'Fan OFF': { msg_type: 15, sub_cmd: 22 },
    'Speaker TEST': { msg_type: 15, sub_cmd: 23 },
    'Clear Lifetime': { msg_type: 15, sub_cmd: 26 },
    'Debug Charge State': { msg_type: 15, sub_cmd: 27 },
    'Get Charge State': { msg_type: 15, sub_cmd: 28 },
    'Force V1 Upgrade': { msg_type: 15, sub_cmd: 29 }
  })

  const [isShowingDetailedStats, setIsShowingDetailedStats] = useState<boolean>(false)
  const [isShowingDisplaySettings, setIsShowingDisplaySettings] = useState<boolean>(false)
  const [isShowingLightSettings, setIsShowingLightSettings] = useState<boolean>(false)
  const [isShowingSpeakerSettings, setIsShowingSpeakerSettings] = useState<boolean>(false)
  const [isShowingPowerOptions, setIsShowingPowerOptions] = useState<boolean>(false)
  const [isShowingConnections, setIsShowingConnections] = useState<boolean | string>(false) // true / false / wifi / ethernet / gsm

  async function handleSendCustomCommand() {
    if (customCommandInputValue != '') {
      try {
        const command = JSON.parse(customCommandInputValue)
        const response = await Comm('/api-websocket/mobile', {
          method: "POST",
          headers: {
            "Charger_id": charger.chargerId,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(command)
        })
        if (response.ok) {
          setNotification(await response.json())
        } else {
          setNotification(await response.text())
        }
      } catch (err) {
        setNotification('Invalid JSON')
      }
    } else {
      setNotification('Can not be empty')
    }
  }

  async function fetchCMD_GET_POWER_STAT() {
    const response = await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 21 })
    })
    const result = await response.json()
    console.log(21, result)
    return result.power_stat
  }

  async function fetchCMD_GET_CONFIG() {
    const response = await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 20 })
    })
    const result = await response.json()
    console.log(20, result)
    return result
  }

  async function fetchValues() {
    const power_stat = await fetchCMD_GET_POWER_STAT()
    const config = await fetchCMD_GET_CONFIG()
    setChargerValues({ power_stat, config })
  }

  useEffect(() => {
    fetchValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // TODO: This is a very stupid solution but I could not come up with a better one at the time
  function updateValueInChargerValues(keys: string, value: string | number | boolean) {
    if (chargerValues) {
      const newChargerValues = structuredClone(chargerValues)
      const path = keys.split('.')
      switch (path.length) {
        case 2:
          // @ts-expect-error: -
          newChargerValues[path[0]][path[1]] = value
          break;
        case 3:
          // @ts-expect-error: -
          newChargerValues[path[0]][path[1]][path[2]] = value
          break;
        case 4:
          // @ts-expect-error: -
          newChargerValues[path[0]][path[1]][path[2]][path[3]] = value
          break;
        case 5:
          // @ts-expect-error: -
          newChargerValues[path[0]][path[1]][path[2]][path[3]][path[4]] = value
          break;
      }
      setChargerValues(newChargerValues)
    }
  }

  async function handleSaveDisplayChanges() {
    if (chargerValues) {
      await Comm('/api-websocket/mobile', {
        method: "POST",
        headers: {
          "Charger_id": charger.chargerId,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ "msg_type": 19, "display": chargerValues.config.display })
      })
      setIsShowingDisplaySettings(false)
    }
  }

  async function handleSaveLightChanges() {
    if (chargerValues) {
      await Comm('/api-websocket/mobile', {
        method: "POST",
        headers: {
          "Charger_id": charger.chargerId,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ "msg_type": 19, "front_led": chargerValues.config.front_led, "rear_led": chargerValues.config.rear_led })
      })
      setIsShowingLightSettings(false)
    }
  }

  async function handleSaveSpeakerChanges() {
    if (chargerValues) {
      await Comm('/api-websocket/mobile', {
        method: "POST",
        headers: {
          "Charger_id": charger.chargerId,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ "msg_type": 19, "sound": chargerValues.config.sound })
      })
      setIsShowingSpeakerSettings(false)
    }
  }

  async function handleSavePowerOptionChanges() {
    if (chargerValues) {
      await Comm('/api-websocket/mobile', {
        method: "POST",
        headers: {
          "Charger_id": charger.chargerId,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ "msg_type": 19, "power": chargerValues.config.power })
      })
      setIsShowingPowerOptions(false)
    }
  }

  function secondsToTimeString(seconds: number) {
    const hours = Math.floor(seconds / 3600)
    const minutes = (seconds - (hours * 3600)) / 60
    return `${('0' + hours).slice(-2)}:${('0' + minutes).slice(-2)}`
  }

  function timeStringToSeconds(time: string) {
    const hours = time.split(':')[0]
    const minutes = time.split(':')[1]
    return (parseInt(hours) * 3600) + (parseInt(minutes) * 60)
  }

  function rgbToHex(r: number, g: number, b: number) {
    const componentToHex = (c: number) => {
      const hex = c.toString(16)
      return hex.length === 1 ? "0" + hex : hex
    }
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b)
  }

  function hexToRgb(hex: string) {
    hex = hex.replace(/^#/, '')
    const bigint = parseInt(hex, 16)
    const r = (bigint >> 16) & 255
    const g = (bigint >> 8) & 255
    const b = bigint & 255
    return { r, g, b }
  }

  async function handleSendBottomRowCommand() {
    if (isSendingBottomRowCommand) {
      const commandToSend = (() => {
        switch (isSendingBottomRowCommand) {
          case 'logUpload': return customCommandDropdownOptions['Force log upload']
          case 'calibrateEvse': return customCommandDropdownOptions['Calibrate EVSE']
          case 'resetInitConfig': return customCommandDropdownOptions['Clear init']
          case 'hardReboot': return customCommandDropdownOptions['Reboot PI']
          case 'softReboot': return customCommandDropdownOptions['Software restart (without reboot)']
          case 'factoryReset': return customCommandDropdownOptions['Reset factory defaults']
          // Not a bottom row command but does the same
          case 'forceUpdate': return customCommandDropdownOptions['Force software upgrade check']
        }
      })()

      const response = await Comm('/api-websocket/mobile', {
        method: "POST",
        headers: {
          "Charger_id": charger.chargerId,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(commandToSend)
      })
      if (response.ok) {
        setNotification(await response.json())
      } else {
        setNotification(await response.text())
      }
      setIsSendingBottomRowCommand(null)
    }
  }

  return (
    <>
      {notification && (
        <Notification setState={setNotification} label={JSON.stringify(notification)} />
      )}
      {isSendingBottomRowCommand && (
        <Modal>
          <SmallModalContainer>
            <ModalCloseButton $theme={theme} src={xIcon} onClick={() => setIsSendingBottomRowCommand(null)} />
            <SmallModalLabel>{`${t('charger.areYouSureAboutSending')}:`}</SmallModalLabel>
            <SmallModalLabel>{t(`charger.${isSendingBottomRowCommand}`)}</SmallModalLabel>
            <WhiteSpace height="32px" />
            <SmallModalButtonContainer>
              <Button onclick={handleSendBottomRowCommand} label={t('charger.send')} width="calc(50% - 8px)" bgcolor="#4AB1FC" labelcolor="white" />
              <WhiteSpace width="16px" />
              <Button onclick={() => setIsSendingBottomRowCommand(null)} label={t('charger.cancel')} width="calc(50% - 8px)" />
            </SmallModalButtonContainer>
          </SmallModalContainer>
        </Modal>
      )}
      <WhiteSpace height={'12px'} />
      <PageTitle label={t('charger.settings')} />
      <Container>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td style={{ width: '20%' }}>
                <SettingContainer $color="dark" onClick={() => setIsShowingDetailedStats(true)}>
                  <SettingIcon src={magnifierIcon} />
                  <SettingLabel $white>{t('charger.powerDetails')}</SettingLabel>
                  <SettingArrow $white src={forwardIcon} />
                </SettingContainer>
              </td>
              <td style={{ width: '20%' }}>
                <SettingContainer $color="gray_200" onClick={() => setIsShowingDisplaySettings(true)}>
                  <SettingIcon src={displayIcon} />
                  <SettingLabel>{t('charger.display')}</SettingLabel>
                  <SettingArrow src={forwardIcon} />
                </SettingContainer>
              </td>
              <td style={{ width: '20%' }}>
                <SettingContainer $color="warning" onClick={() => setIsShowingLightSettings(true)}>
                  <SettingIcon src={lightIcon} />
                  <SettingLabel>{t('charger.lights')}</SettingLabel>
                  <SettingArrow src={forwardIcon} />
                </SettingContainer>
              </td>
              <td style={{ width: '20%' }}>
                <SettingContainer $color="primary" onClick={() => setIsShowingSpeakerSettings(true)}>
                  <SettingIcon src={speakerIcon} />
                  <SettingLabel>{t('charger.speakerOptions')}</SettingLabel>
                  <SettingArrow src={forwardIcon} />
                </SettingContainer>
              </td>
              <td style={{ width: '20%' }}>
                <SettingContainer $color="dark" onClick={() => setIsShowingPowerOptions(true)}>
                  <SettingIcon src={powerIcon} />
                  <SettingLabel $white>{t('charger.powerOptions')}</SettingLabel>
                  <SettingArrow $white src={forwardIcon} />
                </SettingContainer>
              </td>
            </tr>
            <tr>
              <td style={{ width: '20%' }}>
                <SettingContainer $color="gray_200" onClick={() => setIsShowingConnections(true)}>
                  <SettingIcon src={connectionIcon} />
                  <SettingLabel>{t('charger.connections')}</SettingLabel>
                  <SettingArrow src={forwardIcon} />
                </SettingContainer>
              </td>
              <td style={{ width: '20%' }}>
                <SettingContainer $color="dark">
                  <SettingIcon src={midIcon} />
                  <SettingLabel $white>{t('charger.midMetering')}</SettingLabel>
                  <SettingArrow $white src={forwardIcon} />
                </SettingContainer>
              </td>
              <td style={{ width: '20%' }}>
                <SettingContainer $color="primary">
                  <SettingIcon src={rfidIcon} />
                  <SettingLabel>{t('charger.rfid')}</SettingLabel>
                  <SettingArrow src={forwardIcon} />
                </SettingContainer>
              </td>
              <td style={{ width: '20%' }}>
                <SettingContainer $color="gray_200">
                  <SettingIcon src={ocppIcon} />
                  <SettingLabel>{t('charger.ocppSettings')}</SettingLabel>
                  <SettingArrow src={forwardIcon} />
                </SettingContainer>
              </td>
              <td style={{ width: '20%' }}>
                <SettingContainer $color="warning">
                  <SettingIcon src={connectionIcon} />
                  <SettingLabel>{t('charger.powershare')}</SettingLabel>
                  <SettingArrow src={forwardIcon} />
                </SettingContainer>
              </td>
            </tr>
          </tbody>
        </table>
        <WhiteSpace height={'32px'} />
        <DividerLineColor />
        <WhiteSpace height={'32px'} />
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td style={{ width: '20%' }}>
                <SettingContainer $color="white" onClick={() => setIsSendingBottomRowCommand('forceUpdate')}>
                  <SettingIcon src={refreshIcon} />
                  <SettingLabel>{t('charger.forceUpdate')}</SettingLabel>
                  <SettingArrow src={forwardIcon} />
                </SettingContainer>
              </td>
              <td style={{ width: '20%' }}></td>
              <td style={{ width: '20%' }}></td>
              <td style={{ width: '20%' }}></td>
              <td style={{ width: '20%' }}></td>
            </tr>
          </tbody>
        </table>
        <WhiteSpace height={'32px'} />
        <div style={{ minWidth: '30vw', display: 'inline-block' }}>
          <SettingContainer $noFlex $noAnimation $color="white">
            <div style={{ display: 'flex' }}>
              <CustomCommandsIconBackground>
                <SettingIcon src={settingsIcon} />
              </CustomCommandsIconBackground>
              <SettingLabel>{t('charger.customCommands')}</SettingLabel>
              <WhiteSpace width='48px' />
              <div style={{ position: 'absolute', right: '48px', width: '40%', zIndex: '100' }}>
                <Select theme={theme} width={'100%'} label={t('charger.command')} isAbsolute options={Object.keys(customCommandDropdownOptions)} handleSelect={(value) => setCustomCommandInputValue(JSON.stringify(customCommandDropdownOptions[value]))} />
              </div>
            </div>
            <WhiteSpace height='24px' />
            <CustomCommandsTextInput value={customCommandInputValue != '{}' ? customCommandInputValue : ''} onInput={(e) => setCustomCommandInputValue((e.target as HTMLInputElement).value)} />
            <WhiteSpace height='24px' />
            <div style={{ display: 'inline-block' }}>
              <ButtonWithIcon label={t('charger.send')} onClick={handleSendCustomCommand} />
            </div>
          </SettingContainer>
        </div>
        <WhiteSpace height={'32px'} />
        <DividerLineGray />
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td style={{ width: '16.6%' }}>
                <SettingContainer onClick={() => setIsSendingBottomRowCommand('logUpload')}>
                  <SettingIcon $small src={logIcon} />
                  <SettingLabel $small>{t('charger.logUpload')}</SettingLabel>
                </SettingContainer>
              </td>
              <td style={{ width: '16.6%' }}>
                <SettingContainer onClick={() => setIsSendingBottomRowCommand('calibrateEvse')}>
                  <SettingIcon $small src={calibrateIcon} />
                  <SettingLabel $small>{t('charger.calibrateEvse')}</SettingLabel>
                </SettingContainer>
              </td>
              <td style={{ width: '16.6%' }}>
                <SettingContainer onClick={() => setIsSendingBottomRowCommand('resetInitConfig')}>
                  <SettingIcon $small src={resetIcon} />
                  <SettingLabel $small>{t('charger.resetInitConfig')}</SettingLabel>
                </SettingContainer>
              </td>
              <td style={{ width: '16.6%' }}>
                <SettingContainer onClick={() => setIsSendingBottomRowCommand('hardReboot')}>
                  <SettingIcon $small src={rebootIcon} />
                  <SettingLabel $small>{t('charger.hardReboot')}</SettingLabel>
                </SettingContainer>
              </td>
              <td style={{ width: '16.6%' }}>
                <SettingContainer onClick={() => setIsSendingBottomRowCommand('softReboot')}>
                  <SettingIcon $small src={wrenchIcon} />
                  <SettingLabel $small>{t('charger.softReboot')}</SettingLabel>
                </SettingContainer>
              </td>
              <td style={{ width: '16.6%' }}>
                <SettingContainer onClick={() => setIsSendingBottomRowCommand('factoryReset')}>
                  <SettingIcon $small src={errorIcon} />
                  <SettingLabel $small>{t('charger.factoryReset')}</SettingLabel>
                </SettingContainer>
              </td>
            </tr>
          </tbody>
        </table>
        <DividerLineGray />
      </Container>





      {isShowingDetailedStats && chargerValues && (
        <Modal align="right">
          <ModalContainer>
            <ModalCloseButton $theme={theme} src={xIcon} onClick={() => setIsShowingDetailedStats(false)} />
            <ModalTitle>{t('charger.powerDetails')}</ModalTitle>
            <WhiteSpace height="34px" />
            <div style={{ overflowY: 'scroll', maxHeight: "calc(85vh - 100px)" }}>
              {[
                { value1: chargerValues.power_stat.dlm_current1, value2: chargerValues.power_stat.dlm_current2, value3: chargerValues.power_stat.dlm_current3, unit: 'A', title: <><ModalDetailTitleIcon src={amperIcon} /><ModalDetailTitleThin>/</ModalDetailTitleThin><ModalDetailTitleIcon src={homeIcon} /><ModalDetailTitle>{t('charger.amperPhase')}</ModalDetailTitle></> },
                { value1: chargerValues.power_stat.current1, value2: chargerValues.power_stat.current2, value3: chargerValues.power_stat.current3, unit: 'A', title: <><ModalDetailTitleIcon src={amperIcon} /><ModalDetailTitleThin>/</ModalDetailTitleThin><ModalDetailTitleIcon src={chargerSmallIcon} /><ModalDetailTitle>{t('charger.amperPhase')}</ModalDetailTitle></> },
                { value1: chargerValues.power_stat.power1, value2: chargerValues.power_stat.power2, value3: chargerValues.power_stat.power3, unit: 'kW', title: <><ModalDetailTitleIcon src={powerYellowIcon} /><ModalDetailTitle>{t('charger.powerPhase')}</ModalDetailTitle></> },
                { value1: +chargerValues.power_stat.voltage1.toFixed(1), value2: +chargerValues.power_stat.voltage2.toFixed(1), value3: +chargerValues.power_stat.voltage3.toFixed(1), unit: 'V', title: <><ModalDetailTitleIcon src={voltageIcon} /><ModalDetailTitle>{t('charger.voltagePhase')}</ModalDetailTitle></> },
                { value1: chargerValues.power_stat.temp_pow, value2: chargerValues.power_stat.temp_uc, value3: chargerValues.power_stat.temp_pi, unit: '°C', title: <><ModalDetailTitleIcon src={thermometerIcon} /><ModalDetailTitle>{t('charger.temperature')}</ModalDetailTitle><WhiteSpace width='8px' /><ModalDetailTitleThin>PW / UC / PI</ModalDetailTitleThin></> },
                { value1: chargerValues.power_stat.hum, unit: '%', title: <><ModalDetailTitleIcon src={humidityIcon} /><ModalDetailTitle>{t('charger.humidity')}</ModalDetailTitle></> }
              ].map(detail => (
                <>
                  <div style={{ display: 'flex' }}>{detail.title}</div>
                  <WhiteSpace height="8px" />
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <td style={{ width: '33.3%', textAlign: 'left' }}>
                          <DetailedStatsValue>{detail.value1}<DetailedStatsValueUnit>{detail.unit}</DetailedStatsValueUnit></DetailedStatsValue>
                        </td>
                        {detail.value2 != null && <td style={{ width: '33.3%', textAlign: 'center' }}>
                          <DetailedStatsValue>{detail.value2}<DetailedStatsValueUnit>{detail.unit}</DetailedStatsValueUnit></DetailedStatsValue>
                        </td>}
                        {detail.value3 != null && <td style={{ width: '33.3%', textAlign: 'right' }}>
                          <DetailedStatsValue>{detail.value3}<DetailedStatsValueUnit>{detail.unit}</DetailedStatsValueUnit></DetailedStatsValue>
                        </td>}
                      </tr>
                    </tbody>
                  </table>
                  <ModalDetailDivider />
                </>
              ))}
            </div>
            <ModalButtonContainer>
              <ModalButton onClick={fetchValues} $hasBg>{t('charger.refresh')}</ModalButton>
              <ModalButton onClick={() => setIsShowingDetailedStats(false)}>{t('charger.close')}</ModalButton>
            </ModalButtonContainer>
          </ModalContainer>
        </Modal>
      )}





      {isShowingDisplaySettings && chargerValues && (
        <Modal align="right">
          <ModalContainer>
            <ModalCloseButton $theme={theme} src={xIcon} onClick={() => setIsShowingDisplaySettings(false)} />
            <ModalTitle>{t('charger.displaySettings')}</ModalTitle>
            <WhiteSpace height="34px" />
            <Select handleSelect={(value) => updateValueInChargerValues('config.display.language', value == 'English' ? 0 : 1)} theme={theme} width="calc(100% - 20px)" icon={chargerValues.config.display.language == 0 ? flagUk : flagHun} label={t('charger.language')} options={chargerValues.config.display.language == 0 ? ['English', 'Magyar'] : ['Magyar', 'English']} isAbsolute />
            <WhiteSpace height="28px" />
            <div style={{ display: 'flex' }}>
              <ModalDetailTitle>{t('charger.displayEnabled')}</ModalDetailTitle>
              <div style={{ position: 'absolute', right: '20px' }}>
                <Switch toggled={chargerValues.config.display.enabled} setToggled={(value) => updateValueInChargerValues('config.display.enabled', value)} />
              </div>
            </div>
            <WhiteSpace height="8px" />
            <ModalDetailDivider />
            <div style={{ display: 'flex' }}>
              <ModalDetailTitle>{t('charger.eyesEnabled')}</ModalDetailTitle>
              <div style={{ position: 'absolute', right: '20px' }}>
                <Switch toggled={chargerValues.config.display.eyes_en} setToggled={(value) => updateValueInChargerValues('config.display.eyes_en', value)} />
              </div>
            </div>
            <WhiteSpace height="8px" />
            <ModalDetailDivider />
            <div style={{ display: 'flex' }}>
              <ModalDetailTitle>{t('charger.nightmodeEnabled')}</ModalDetailTitle>
              <div style={{ position: 'absolute', right: '20px' }}>
                <Switch toggled={chargerValues.config.display.night_mode_en} setToggled={(value) => updateValueInChargerValues('config.display.night_mode_en', value)} />
              </div>
            </div>
            <WhiteSpace height="8px" />

            <ModalDetailDivider $isColor />

            <WhiteSpace height="8px" />
            <ModalDetailTitle>{t('charger.textScrollingSpeed')}</ModalDetailTitle>
            <WhiteSpace height="18px" />
            <Slider value={chargerValues.config.display.scroll_speed} setValue={(value) => updateValueInChargerValues('config.display.scroll_speed', value)} min={1} max={100} step={1} numberOfDots={5} width="100%" firstLabel={t('charger.slow')} lastLabel={t('charger.fast')} />
            <WhiteSpace height="24px" />
            <ModalDetailDivider />
            <WhiteSpace height="8px" />
            <ModalDetailTitle>{t('charger.brightness')}</ModalDetailTitle>
            <WhiteSpace height="18px" />
            <Slider value={chargerValues.config.display.brightness} setValue={(value) => updateValueInChargerValues('config.display.brightness', value)} min={10} max={100} step={1} numberOfDots={5} width="100%" firstImg={dimIcon} lastImg={brightIcon} />
            <WhiteSpace height="24px" />
            <ModalDetailDivider />
            <WhiteSpace height="8px" />
            <div style={{ display: 'flex' }}>
              <ModalDetailTitle>{t('charger.displayStaysActive')}</ModalDetailTitle>
              <WhiteSpace width="4px" />
              <ModalDetailTitleThin>{`(${t('charger.minute')})`}</ModalDetailTitleThin>
            </div>
            <WhiteSpace height="18px" />
            <Slider value={chargerValues.config.display.on_seconds == 0 ? 600 : chargerValues.config.display.on_seconds} setValue={(value) => updateValueInChargerValues('config.display.on_seconds', value == 600 ? 0 : value)} min={60} max={600} step={1} numberOfDots={11} width="100%" dotLabels={['1', '2', '3', '4', '5', '6', '7', '8', '9', '∞']} />

            <ModalButtonContainer>
              <ModalButton onClick={handleSaveDisplayChanges} $hasBg>{t('charger.save')}</ModalButton>
              <ModalButton onClick={() => setIsShowingDisplaySettings(false)}>{t('charger.cancel')}</ModalButton>
            </ModalButtonContainer>
          </ModalContainer>
        </Modal>
      )}





      {isShowingLightSettings && chargerValues && (
        <Modal align="right">
          <ModalContainer>
            <ModalCloseButton $theme={theme} src={xIcon} onClick={() => setIsShowingLightSettings(false)} />
            <ModalTitle>{t('charger.lightSettings')}</ModalTitle>
            <WhiteSpace height="34px" />
            <div style={{ display: 'flex' }}>
              <ModalDetailTitle>{t('charger.frontLight')}</ModalDetailTitle>
              <WhiteSpace width='8px' />
              <ModalDetailTitleThin>{`(${t('charger.mouth')})`}</ModalDetailTitleThin>
              <div style={{ position: 'absolute', right: '20px' }}>
                <Switch toggled={chargerValues.config.front_led.enabled} setToggled={(value) => updateValueInChargerValues('config.front_led.enabled', value)} />
              </div>
            </div>
            <WhiteSpace height="42px" />
            <Slider value={chargerValues.config.front_led.brightness} setValue={(value) => updateValueInChargerValues('config.front_led.brightness', value)} min={10} max={100} step={1} numberOfDots={5} width="100%" firstImg={dimIcon} lastImg={brightIcon} />
            <WhiteSpace height="32px" />
            <ModalDetailDivider />
            <div style={{ display: 'flex' }}>
              <ModalDetailTitle>{t('charger.turnedOffPeriod')}</ModalDetailTitle>
              <div style={{ position: 'absolute', right: '20px' }}>
                <Switch toggled={chargerValues.config.front_led.night_mode_en} setToggled={(value) => updateValueInChargerValues('config.front_led.night_mode_en', value)} />
              </div>
            </div>
            {chargerValues.config.front_led.night_mode_en && (
              <>
                <WhiteSpace height="18px" />
                <ModalDetailTitle>{t('charger.timeIntervall')}</ModalDetailTitle>
                <WhiteSpace height="8px" />
                <TimeInput type="time" value={secondsToTimeString(chargerValues.config.front_led.night_period_st)} onChange={(e) => updateValueInChargerValues('config.front_led.night_period_st', timeStringToSeconds(e.target.value))} />
                <TimeInput $right type="time" value={secondsToTimeString(chargerValues.config.front_led.night_period_sp)} onChange={(e) => updateValueInChargerValues('config.front_led.night_period_sp', timeStringToSeconds(e.target.value))} />
              </>
            )}
            <WhiteSpace height="16px" />

            <ModalDetailDivider $isColor />

            <WhiteSpace height="8px" />
            <div style={{ display: 'flex' }}>
              <ModalDetailTitle>{t('charger.backLight')}</ModalDetailTitle>
              <WhiteSpace width='8px' />
              <ModalDetailTitleThin>{`(${t('charger.backpanel')})`}</ModalDetailTitleThin>
              <div style={{ position: 'absolute', right: '20px' }}>
                <Switch toggled={chargerValues.config.rear_led.enabled} setToggled={(value) => updateValueInChargerValues('config.rear_led.enabled', value)} />
              </div>
            </div>
            <WhiteSpace height="42px" />
            <Slider value={chargerValues.config.rear_led.brightness} setValue={(value) => updateValueInChargerValues('config.rear_led.brightness', value)} min={10} max={100} step={1} numberOfDots={5} width="100%" firstImg={dimIcon} lastImg={brightIcon} />
            <WhiteSpace height="32px" />
            <ModalDetailDivider />
            <div style={{ display: 'flex' }}>
              <ModalDetailTitle>{t('charger.color')}</ModalDetailTitle>
              <div style={{ position: 'absolute', right: '20px' }}>
                <ColorInput type="color"
                  value={rgbToHex(chargerValues.config.rear_led.color_r, chargerValues.config.rear_led.color_g, chargerValues.config.rear_led.color_b)}
                  onChange={(e) => {
                    const rgb = hexToRgb(e.target.value)
                    const newChargerValues: ChargerValuesType = structuredClone(chargerValues)
                    newChargerValues.config.rear_led.color_r = rgb.r
                    newChargerValues.config.rear_led.color_g = rgb.g
                    newChargerValues.config.rear_led.color_b = rgb.b
                    setChargerValues(newChargerValues)
                  }}
                />
              </div>
            </div>
            <WhiteSpace height="8px" />
            <ModalDetailDivider />
            <div style={{ display: 'flex' }}>
              <ModalDetailTitle>{t('charger.turnedOffPeriod')}</ModalDetailTitle>
              <div style={{ position: 'absolute', right: '20px' }}>
                <Switch toggled={chargerValues.config.rear_led.night_mode_en} setToggled={(value) => updateValueInChargerValues('config.rear_led.night_mode_en', value)} />
              </div>
            </div>
            {chargerValues.config.rear_led.night_mode_en && (
              <>
                <WhiteSpace height="18px" />
                <ModalDetailTitle>{t('charger.timeIntervall')}</ModalDetailTitle>
                <WhiteSpace height="8px" />
                <TimeInput type="time" value={secondsToTimeString(chargerValues.config.rear_led.night_period_st)} onChange={(e) => updateValueInChargerValues('config.rear_led.night_period_st', timeStringToSeconds(e.target.value))} />
                <TimeInput $right type="time" value={secondsToTimeString(chargerValues.config.rear_led.night_period_sp)} onChange={(e) => updateValueInChargerValues('config.rear_led.night_period_sp', timeStringToSeconds(e.target.value))} />
              </>
            )}
          </ModalContainer>
          <ModalButtonContainer>
            <ModalButton onClick={handleSaveLightChanges} $hasBg>{t('charger.save')}</ModalButton>
            <ModalButton onClick={() => setIsShowingLightSettings(false)}>{t('charger.cancel')}</ModalButton>
          </ModalButtonContainer>
        </Modal>
      )}





      {isShowingSpeakerSettings && chargerValues && (
        <Modal align="right">
          <ModalContainer>
            <ModalCloseButton $theme={theme} src={xIcon} onClick={() => setIsShowingSpeakerSettings(false)} />
            <ModalTitle>{t('charger.speakerSettings')}</ModalTitle>
            <WhiteSpace height="34px" />
            <div style={{ display: 'flex' }}>
              <ModalDetailTitle>{t('charger.speakerState')}</ModalDetailTitle>
              <div style={{ position: 'absolute', right: '20px' }}>
                <Switch toggled={chargerValues.config.sound.enabled} setToggled={(value) => updateValueInChargerValues('config.sound.enabled', value)} />
              </div>
            </div>
            <WhiteSpace height="8px" />
            <ModalDetailDivider />
            <div style={{ display: 'flex' }}>
              <ModalDetailTitle>{t('charger.turnedOffPeriod')}</ModalDetailTitle>
              <div style={{ position: 'absolute', right: '20px' }}>
                <Switch toggled={chargerValues.config.sound.night_mode_en} setToggled={(value) => updateValueInChargerValues('config.sound.night_mode_en', value)} />
              </div>
            </div>
            {chargerValues.config.sound.night_mode_en && (
              <>
                <WhiteSpace height="18px" />
                <ModalDetailTitle>{t('charger.timeIntervall')}</ModalDetailTitle>
                <WhiteSpace height="8px" />
                <TimeInput type="time" value={secondsToTimeString(chargerValues.config.sound.night_period_st)} onChange={(e) => updateValueInChargerValues('config.sound.night_period_st', timeStringToSeconds(e.target.value))} />
                <TimeInput $right type="time" value={secondsToTimeString(chargerValues.config.sound.night_period_sp)} onChange={(e) => updateValueInChargerValues('config.sound.night_period_sp', timeStringToSeconds(e.target.value))} />
              </>
            )}
          </ModalContainer>
          <ModalButtonContainer>
            <ModalButton onClick={handleSaveSpeakerChanges} $hasBg>{t('charger.save')}</ModalButton>
            <ModalButton onClick={() => setIsShowingSpeakerSettings(false)}>{t('charger.cancel')}</ModalButton>
          </ModalButtonContainer>
        </Modal>
      )}





      {isShowingPowerOptions && chargerValues && (
        <Modal align="right">
          <ModalContainer style={{ position: 'relative', overflowY: 'scroll', overflowX: 'hidden' }}>
            <ModalCloseButton $theme={theme} src={xIcon} onClick={() => setIsShowingPowerOptions(false)} />
            <ModalTitle>{t('charger.powerOptions')}</ModalTitle>
            <WhiteSpace height="34px" />


            <div style={{ display: 'flex' }}>
              <ModalDetailTitleIcon src={averageIcon} />
              <ModalDetailTitleThin>{`${t('charger.maxAmper')}:`}</ModalDetailTitleThin>
              <WhiteSpace width='8px' />
              <ModalDetailTitle>{`${chargerValues.config.power.curr_usr_limit} A`}</ModalDetailTitle>
              <div style={{ display: 'flex', position: 'absolute', right: '20px' }}>
                <ModalDetailTitleThin>{`${t('charger.maxPower')}:`}</ModalDetailTitleThin>
                <WhiteSpace width='8px' />
                <ModalDetailTitle>{`${chargerValues.config.power.curr_usr_limit * (230 * 3) / 1000} kW`}</ModalDetailTitle>
              </div>
            </div>
            <WhiteSpace height="18px" />
            <Slider value={chargerValues.config.power.curr_usr_limit} setValue={(value) => updateValueInChargerValues('config.power.curr_usr_limit', value)} min={chargerValues.config.power.curr_hw_limit_min} max={chargerValues.config.power.curr_hw_limit} step={1} numberOfDots={5} width="100%" firstLabel={`Min. (${chargerValues.config.power.curr_hw_limit_min})`} lastLabel={`Max. (${chargerValues.config.power.curr_hw_limit})`} />
            <WhiteSpace height="32px" />

            <ModalDetailDivider $isColor />

            <WhiteSpace height="8px" />
            <div style={{ display: 'flex' }}>
              <ModalDetailTitle>{t('charger.scheduledPower')}</ModalDetailTitle>
              <div style={{ position: 'absolute', right: '20px' }}>
                <Switch toggled={chargerValues.config.power.night_mode_en} setToggled={(value) => updateValueInChargerValues('config.power.night_mode_en', value)} />
              </div>
            </div>
            {chargerValues.config.power.night_mode_en && (
              <>
                <WhiteSpace height='32px' />
                <div style={{ display: 'flex' }}>
                  <ModalDetailTitleIcon src={averageIcon} />
                  <ModalDetailTitleThin>{`${t('charger.maxAmper')}:`}</ModalDetailTitleThin>
                  <WhiteSpace width='8px' />
                  <ModalDetailTitle>{`${chargerValues.config.power.curr_night_limit} A`}</ModalDetailTitle>
                  <div style={{ display: 'flex', position: 'absolute', right: '20px' }}>
                    <ModalDetailTitleThin>{`${t('charger.maxPower')}:`}</ModalDetailTitleThin>
                    <WhiteSpace width='8px' />
                    <ModalDetailTitle>{`${chargerValues.config.power.curr_night_limit * (230 * 3) / 1000} kW`}</ModalDetailTitle>
                  </div>
                </div>
                <WhiteSpace height="18px" />
                <Slider value={chargerValues.config.power.curr_night_limit} setValue={(value) => updateValueInChargerValues('config.power.curr_night_limit', value)} min={chargerValues.config.power.curr_hw_limit_min} max={chargerValues.config.power.curr_hw_limit} step={1} numberOfDots={5} width="100%" firstLabel={`Min. (${chargerValues.config.power.curr_hw_limit_min})`} lastLabel={`Max. (${chargerValues.config.power.curr_hw_limit})`} />
                <div>
                  <WhiteSpace height="48px" />
                  <ModalDetailTitle>{t('charger.timeIntervall')}</ModalDetailTitle>
                  <WhiteSpace height="8px" />
                  <TimeInput type="time" value={secondsToTimeString(chargerValues.config.power.night_period_st)} onChange={(e) => updateValueInChargerValues('config.power.night_period_st', timeStringToSeconds(e.target.value))} />
                  <TimeInput $right type="time" value={secondsToTimeString(chargerValues.config.power.night_period_sp)} onChange={(e) => updateValueInChargerValues('config.power.night_period_sp', timeStringToSeconds(e.target.value))} />
                </div>
              </>
            )}
            <WhiteSpace height="16px" />

            <ModalDetailDivider $isColor />

            <WhiteSpace height="8px" />
            <div style={{ display: 'flex' }}>
              <ModalDetailTitle>{t('charger.inteligentPower')}</ModalDetailTitle>
              <div style={{ position: 'absolute', right: '20px' }}>
                <Switch toggled={chargerValues.config.power.dlm_mode != 0} setToggled={(value) => {
                  if (chargerValues.config.power.dlm_meter_state != 0 && chargerValues.config.power.dlm_meter_state != 1) {
                    updateValueInChargerValues('config.power.dlm_mode', value ? 1 : 0)
                  }
                }} />
              </div>
            </div>
            {(chargerValues.config.power.dlm_meter_state == 0 || chargerValues.config.power.dlm_meter_state == 1) && (
              <>
                <WhiteSpace height='16px' />
                <div style={{ display: 'flex' }}>
                  <ModalDetailTitleIcon src={warningIcon} />
                  <WhiteSpace width='4px' />
                  <ModalDetailTitleThin>{t('charger.externalMeterIsNotAvailable')}</ModalDetailTitleThin>
                </div>
              </>
            )}
            {chargerValues.config.power.dlm_mode != 0 && (
              <>
                <WhiteSpace height='32px' />
                <div style={{ display: 'flex' }}>
                  <ModalDetailTitleIcon src={averageIcon} />
                  <ModalDetailTitleThin>{`${t('charger.maxAmper')}:`}</ModalDetailTitleThin>
                  <WhiteSpace width='8px' />
                  <ModalDetailTitle>{`${chargerValues.config.power.dlm_dyn_curr_lim} A`}</ModalDetailTitle>
                  <div style={{ display: 'flex', position: 'absolute', right: '20px' }}>
                    <ModalDetailTitleThin>{`${t('charger.maxPower')}:`}</ModalDetailTitleThin>
                    <WhiteSpace width='8px' />
                    <ModalDetailTitle>{`${chargerValues.config.power.dlm_dyn_curr_lim * (230 * 3) / 1000} kW`}</ModalDetailTitle>
                  </div>
                </div>
                <WhiteSpace height="18px" />
                <Slider value={chargerValues.config.power.dlm_dyn_curr_lim} setValue={(value) => updateValueInChargerValues('config.power.dlm_dyn_curr_lim', value)} min={chargerValues.config.power.curr_hw_limit_min} max={chargerValues.config.power.curr_hw_limit} step={1} numberOfDots={5} width="100%" firstLabel={`Min. (${chargerValues.config.power.curr_hw_limit_min})`} lastLabel={`Max. (${chargerValues.config.power.curr_hw_limit})`} />
              </>
            )}
            {chargerValues.config.power.dlm_mode != 0 && (
              <>
                <WhiteSpace height="16px" />

                <ModalDetailDivider $isColor />

                <WhiteSpace height="8px" />
                <div style={{ display: 'flex' }}>
                  <ModalDetailTitle>{t('charger.solarPower')}</ModalDetailTitle>
                  <div style={{ position: 'absolute', right: '20px' }}>
                    <Switch toggled={chargerValues.config.power.dlm_mode == 2 || chargerValues.config.power.dlm_mode == 3} setToggled={(value) => updateValueInChargerValues('config.power.dlm_mode', value ? 2 : 1)} />
                  </div>
                </div>
                <WhiteSpace height="32px" />
                {(chargerValues.config.power.dlm_mode == 2 || chargerValues.config.power.dlm_mode == 3) && (
                  <div style={{ display: 'flex' }}>
                    <img src={solarEcoModeIcon} style={chargerValues.config.power.dlm_mode == 2 ? { width: '41%', cursor: 'pointer', position: 'absolute', left: '32px' } : { width: '41%', filter: 'grayscale(1)', cursor: 'pointer', position: 'absolute', left: '32px' }} onClick={() => updateValueInChargerValues('config.power.dlm_mode', 2)} />
                    <img src={solarGreenModeIcon} style={chargerValues.config.power.dlm_mode == 3 ? { width: '41%', cursor: 'pointer', position: 'absolute', right: '32px' } : { width: '41%', filter: 'grayscale(1)', cursor: 'pointer', position: 'absolute', right: '32px' }} onClick={() => updateValueInChargerValues('config.power.dlm_mode', 3)} />
                  </div>
                )}
              </>
            )}
            <WhiteSpace height="175px" />
          </ModalContainer>
          <ModalButtonContainer>
            <ModalButton onClick={handleSavePowerOptionChanges} $hasBg>{t('charger.save')}</ModalButton>
            <ModalButton onClick={() => setIsShowingPowerOptions(false)}>{t('charger.cancel')}</ModalButton>
          </ModalButtonContainer>
        </Modal>
      )}





      {isShowingConnections && chargerValues && (
        <Modal align="right">
          <ModalContainer>
            <ModalCloseButton $theme={theme} src={xIcon} onClick={() => setIsShowingConnections(false)} />
            {isShowingConnections === true && (
              <ModalTitle>{t('charger.connections')}</ModalTitle>
            )}
            {isShowingConnections == 'wifi' && (
              <div style={{ display: 'flex' }}>
                <ConnectionsBackIcon src={forwardIcon} onClick={() => setIsShowingConnections(true)} />
                <ModalSubcategoryTitle>{t('charger.wifi')}</ModalSubcategoryTitle>
              </div>
            )}
            {isShowingConnections == 'ethernet' && (
              <div style={{ display: 'flex' }}>
                <ConnectionsBackIcon src={forwardIcon} onClick={() => setIsShowingConnections(true)} />
                <ModalSubcategoryTitle>{t('charger.ethernet')}</ModalSubcategoryTitle>
              </div>
            )}
            {isShowingConnections == 'gsm' && (
              <div style={{ display: 'flex' }}>
                <ConnectionsBackIcon src={forwardIcon} onClick={() => setIsShowingConnections(true)} />
                <ModalSubcategoryTitle>{t('charger.cellular')}</ModalSubcategoryTitle>
              </div>
            )}
            <WhiteSpace height="34px" />
            {isShowingConnections === true && (
              <>
                <SettingContainer $color="gray_300" onClick={() => setIsShowingConnections('wifi')}>
                  <SettingIcon $small src={wifiIcon} />
                  <SettingLabel>{`${t('charger.wifi')} ${t('charger.settings')}`}</SettingLabel>
                  <SettingArrow src={forwardIcon} />
                  {chargerValues.config.wifi.mode != 0 && (
                    <ConnectionActiveLabel>{t('charger.active')}</ConnectionActiveLabel>
                  )}
                </SettingContainer>
                <SettingContainer $color="gray_300" onClick={() => setIsShowingConnections('ethernet')}>
                  <SettingIcon $small src={ethernetIcon} />
                  <SettingLabel>{`${t('charger.ethernet')} ${t('charger.settings')}`}</SettingLabel>
                  <SettingArrow src={forwardIcon} />
                  {chargerValues.config.ethernet.mode != 0 && (
                    <ConnectionActiveLabel>{t('charger.active')}</ConnectionActiveLabel>
                  )}
                </SettingContainer>
                <SettingContainer $color="gray_300" onClick={() => setIsShowingConnections('gsm')}>
                  <SettingIcon $small src={cellularIcon} />
                  <SettingLabel>{`${t('charger.cellular')} ${t('charger.settings')}`}</SettingLabel>
                  <SettingArrow src={forwardIcon} />
                  {chargerValues.config.gsm.mode != 0 && (
                    <ConnectionActiveLabel>{t('charger.active')}</ConnectionActiveLabel>
                  )}
                </SettingContainer>
              </>
            )}
            {isShowingConnections == 'wifi' && (
              <>
                {[
                  {label: 'wifiSsid', value: chargerValues.config.wifi.ssid || '-'},
                  {label: 'wifiPassword', value: chargerValues.config.wifi.passwd || '-'},
                  {label: 'wifiApModeSsid', value: chargerValues.config.wifi.ap_mode_ssid || '-'}
                ].map(row => (
                  <div style={{ display: 'flex', marginBottom: '24px' }}>
                    <ModalDetailTitle>{t(`charger.${row.label}`)}</ModalDetailTitle>
                    <ModalDetailTitleThin style={{ position: 'absolute', right: '24px' }}>{row.value}</ModalDetailTitleThin>
                  </div>
                ))}
              </>
            )}
            {isShowingConnections == 'ethernet' && (
              <>
                {[
                  {label: 'ipAddress', value: chargerValues.config.ethernet.ip_address || '-'},
                  {label: 'dns', value: chargerValues.config.ethernet.dns || '-'},
                  {label: 'gateway', value: chargerValues.config.ethernet.gateway || '-'},
                  {label: 'netmask', value: chargerValues.config.ethernet.netmask || '-'},
                ].map(row => (
                  <div style={{ display: 'flex', marginBottom: '24px' }}>
                    <ModalDetailTitle>{t(`charger.${row.label}`)}</ModalDetailTitle>
                    <ModalDetailTitleThin style={{ position: 'absolute', right: '24px' }}>{row.value}</ModalDetailTitleThin>
                  </div>
                ))}
              </>
            )}
            {isShowingConnections == 'gsm' && (
              <>
                {[
                  {label: 'iccid', value: chargerValues.config.gsm.IDs.iccid || '-'},
                  {label: 'imei', value: chargerValues.config.gsm.IDs.imei || '-'},
                  {label: 'imsi', value: chargerValues.config.gsm.IDs.imsi || '-'},
                  {label: 'revision', value: chargerValues.config.gsm.IDs.revision || '-'},
                  {label: 'apn', value: chargerValues.config.gsm.apn || '-'},
                  {label: 'apnPwd', value: chargerValues.config.gsm.apn_pwd || '-'},
                  {label: 'apnUname', value: chargerValues.config.gsm.apn_uname || '-'},
                  {label: 'nwRegToSec', value: chargerValues.config.gsm.nw_reg_to_sec|| '-'},
                  {label: 'pinError', value: chargerValues.config.gsm.pin_error || '-'},
                  {label: 'simPin', value: chargerValues.config.gsm.sim_pin || '-'},
                ].map(row => (
                  <div style={{ display: 'flex', marginBottom: '24px' }}>
                    <ModalDetailTitle>{t(`charger.${row.label}`)}</ModalDetailTitle>
                    <ModalDetailTitleThin style={{ position: 'absolute', right: '24px' }}>{row.value}</ModalDetailTitleThin>
                  </div>
                ))}
              </>
            )}
          </ModalContainer>
          <ModalButtonContainer>
            <ModalButton onClick={() => setIsShowingConnections(false)}>{t('charger.close')}</ModalButton>
          </ModalButtonContainer>
        </Modal>
      )}
    </>
  )
}