import { useTranslation } from "react-i18next";
import PageTitle from "../../components/PageTitle";
import styled from "styled-components";
import { useEffect, useState } from "react";
import Comm from "../../Comm";
import refreshIcon from "../../assets/icons/refreshIcon.png"
import WhiteSpace from "../../components/Whitespace";
import ChargerType from "../../types/ChargerType";
import ExportModal from "../../modules/ExportModal";
import chargerOnlineIcon from "../../assets/icons/chargerOnlineIcon.png"
import chargerOfflineIcon from "../../assets/icons/chargerOfflineIcon.png"
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import InputWithLabel from "../../components/InputWithLabel";
import dateToTimeAgoString from "../../utils/dateToTimeAgoString";
import editIcon from "../../assets/icons/editIcon.png"
import Table from "../../modules/Table";
import { TableCellTypes } from "../../enums/TableCellTypes";
import ButtonWithIcon from "../../components/ButtonWithIcon";

const RefreshTimerLabel = styled.p`
  font-weight: 400;
  font-size: 10px;
  color: ${props => props.theme.colors.gray_600};
  margin-top: 4px;
  text-align: center;
`

const ToolbarContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 26px;
  display: flex;
`

const SmallModalContainer = styled.div`
  padding: 24px;
`

const SmallModalButtonContainer = styled.div`
  width: 15vw;
  display: flex;
`

export default function Chargers() {
  const { t } = useTranslation()

  const [chargers, setChargers] = useState<ChargerType[]>()
  const [lastRefresh, setLastRefresh] = useState<string>('')
  const [isEditingChargerValues, setIsEditingChargerValues] = useState<ChargerType | null>()
  const [newSerialNumberInputValue, setNewSerialNumberInputValue] = useState<string>('')
  const [newChargerIdInputValue, setNewChargerIdInputValue] = useState<string>('')
  const [newLocalNameInputValue, setNewLocalNameInputValue] = useState<string>('')

  async function fetchChargers() {
    const response = await Comm('/api-charger/chargers', {})
    const result = await response.json()
    setLastRefresh(new Date().toString().split(' ')[4])
    setChargers(result)
  }
  useEffect(() => {
    fetchChargers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handelRefresh() {
    setChargers([])
    fetchChargers()
  }

  function handleExportChargers(formats: string[]) {
    console.log(`TODO: Export chargers in formats: ${formats}`)
  }

  async function handleEditChargerValues() {
    if (isEditingChargerValues) {
      await Comm('/api-charger/charger/update', {
        method: 'PUT',
        body: JSON.stringify({
          chargerId: isEditingChargerValues.chargerId,
          serialNumber: newSerialNumberInputValue.length > 0 ? newSerialNumberInputValue : ' ',
          firmwareVersion: '',
          softwareVersion: '',
          osVersion: '',
          localName: newLocalNameInputValue.length > 0 ? newLocalNameInputValue : ' ',
          initedCharger: {
            chargerId: '',
            userId: ''
          }
        }),
        headers: {
          'charger_id': isEditingChargerValues.chargerId,
          'Content-Type': 'application/json'
        }
      })
      isEditingChargerValues.serialNumber = newSerialNumberInputValue
      isEditingChargerValues.chargerId = newChargerIdInputValue
      isEditingChargerValues.localName = newLocalNameInputValue
      setNewSerialNumberInputValue('')
      setNewChargerIdInputValue('')
      setNewLocalNameInputValue('')
      setIsEditingChargerValues(null)
      handelRefresh()
    }
  }

  // Set the values of the 3 input fields when editing a chargers values begins
  useEffect(() => {
    if (isEditingChargerValues) {
      setNewSerialNumberInputValue(isEditingChargerValues.serialNumber || '')
      setNewChargerIdInputValue(isEditingChargerValues.chargerId || '')
      setNewLocalNameInputValue(isEditingChargerValues.localName || '')
    }
  }, [isEditingChargerValues])

  return (
    <>
      <PageTitle label={t('chargers.chargers')} />
      {isEditingChargerValues && (
        <Modal>
          <SmallModalContainer>
            <InputWithLabel autoFocus width='100%' label={t('chargers.newChargerId')} value={newChargerIdInputValue} onInput={() => { }} />
            <WhiteSpace height="16px" />
            <InputWithLabel width='100%' label={t('chargers.newSerialNumber')} value={newSerialNumberInputValue} onInput={(value) => setNewSerialNumberInputValue(value)} />
            <WhiteSpace height="16px" />
            <InputWithLabel width='100%' label={t('chargers.newLocalName')} value={newLocalNameInputValue} onInput={(value) => setNewLocalNameInputValue(value)} />
            <WhiteSpace height="16px" />
            <SmallModalButtonContainer>
              <Button onclick={handleEditChargerValues} label={t('chargers.save')} width="calc(50% - 8px)" bgcolor="#4AB1FC" labelcolor="white" />
              <WhiteSpace width="16px" />
              <Button onclick={() => setIsEditingChargerValues(null)} label={t('chargers.cancel')} width="calc(50% - 8px)" />
            </SmallModalButtonContainer>
          </SmallModalContainer>
        </Modal>
      )}
      <ToolbarContainer>
        <div>
          <ButtonWithIcon icon={refreshIcon} label={t('chargers.refresh')} onClick={handelRefresh} backgroundColor="success" recolorIconTo="white" />
          <RefreshTimerLabel>{lastRefresh}</RefreshTimerLabel>
        </div>
        <WhiteSpace width='8px' />
        <div>
          <ExportModal onExport={handleExportChargers} title={t('chargers.exportChargers')} />
        </div>
      </ToolbarContainer>
      {chargers && chargers.length > 0 && (
        <Table id="Chargers" height="calc(90vh - 170px)" isSearchEnabled={true} isPaginationEnabled={true} columns={[
          { width: '30px' },
          { label: t('chargers.chargerId'), width: '13%' },
          { label: t('chargers.userIdUserEmail'), width: '20%' },
          { label: t('chargers.status'), width: '8%' },
          { label: t('chargers.lastOnline'), width: '9%' },
          { label: t('chargers.registrationDate'), width: '9%' },
          { label: t('chargers.fwVersion'), width: '6%' },
          { label: t('chargers.swVersion'), width: '6%' },
          { label: t('chargers.osVersion'), width: '6%' },
          { label: t('chargers.serialNumberLocalName') },
          { width: '12px' },
          { width: '70px' }
        ]} data={chargers.map(charger => [
          {
            link: `/chargers/${charger.chargerId}`,
            value: {
              type: TableCellTypes.Image,
              src: charger.online ? chargerOnlineIcon : chargerOfflineIcon
            }
          }, {
            link: `/chargers/${charger.chargerId}`,
            sortBy: charger.chargerId,
            value: {
              type: TableCellTypes.Label,
              value: charger.chargerId
            }
          }, {
            link: `/chargers/${charger.chargerId}`,
            sortBy: charger.userId,
            value: {
              type: TableCellTypes.LabelDivided,
              value1: charger.userId,
              value2: charger.userEmail
            }
          }, {
            link: `/chargers/${charger.chargerId}`,
            sortBy: charger.online ? '1' : '0',
            value: {
              type: TableCellTypes.Status,
              label: charger.online ? 'Online' : 'Offline',
              themeColor: charger.online ? 'success' : 'gray_400',
              labelBelow: charger.online ? null : dateToTimeAgoString(new Date(charger.lastPresence.replace(/\./g, '-')))
            }
          }, {
            link: `/chargers/${charger.chargerId}`,
            sortBy: charger.lastPresence,
            value: {
              type: TableCellTypes.LabelDivided,
              value1: charger.lastPresence.split(' ')[0],
              value2: charger.lastPresence.split(' ')[1]
            }
          }, {
            link: `/chargers/${charger.chargerId}`,
            sortBy: charger.registrationDate,
            value: {
              type: TableCellTypes.LabelDivided,
              value1: charger.registrationDate.split(' ')[0],
              value2: charger.registrationDate.split(' ')[1]
            }
          }, {
            link: `/chargers/${charger.chargerId}`,
            sortBy: charger.fwVersion || '-',
            value: {
              type: TableCellTypes.Label,
              value: charger.fwVersion || '-'
            }
          }, {
            link: `/chargers/${charger.chargerId}`,
            sortBy: charger.swVersion || '-',
            value: {
              type: TableCellTypes.Label,
              value: charger.swVersion || '-'
            }
          }, {
            link: `/chargers/${charger.chargerId}`,
            sortBy: charger.osVersion || '-',
            value: {
              type: TableCellTypes.Label,
              value: charger.osVersion || '-'
            }
          }, {
            onClick: () => setIsEditingChargerValues(charger),
            sortBy: charger.serialNumber == ' ' ? '-' : charger.serialNumber || '-',
            value: {
              type: TableCellTypes.LabelDivided,
              value1: charger.serialNumber == ' ' ? '-' : charger.serialNumber || '-',
              value2: charger.localName || '-'
            }
          }, {
            onClick: () => setIsEditingChargerValues(charger),
            value: {
              type: TableCellTypes.Icon,
              src: editIcon
            }
          }, {
            link: `/chargers/${charger.chargerId}`,
            value: {
              type: TableCellTypes.OpenButton
            }
          }
        ])} />
      )}
    </>
  )
}